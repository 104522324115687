import locale from 'locales/en'

export const APP_HOST = process.env.APP_HOST || 'http://localhost:4000'

export const PORTAL_HOST = process.env.PORTAL_HOST || 'http://localhost:4000'

export const API_HOST = process.env.API_HOST || 'http://localhost:3000'

export const WIDGET_API_HOST = process.env.WIDGET_API_HOST || 'http://localhost:3000'

export const WIDGET_HOST = process.env.WIDGET_HOST || 'http://localhost:4002'

export const CABLE_HOST = process.env.CABLE_HOST || 'ws://localhost:3000/cable'

export const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY || 'AIzaSyDSPz5HVZ7EQhOrXCPoFU_VXbPkCkqaXn0'

export const PAYPAL_CUSTOM_SCRIPT =
  process.env.PAYPAL_CUSTOM_SCRIPT ||
  'https://www.sandbox.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js'

// Uncomment to send mixpanel events locally
// export const MIXPANEL_PROJECT_TOKEN = '5edf2d932d32ef689090991a2891a791';
// eslint-disable-next-line prefer-destructuring
export const MIXPANEL_PROJECT_TOKEN = process.env.MIXPANEL_PROJECT_TOKEN

export const ALERT_KINDS = {
  videocallInfo: 'videocall-info',
  videocall: 'videocall',
  success: 'success',
  error: 'error',
  warning: 'warning',
  warningInfo: 'warning-info',
  info: 'info',
  infoOutlined: 'infoOutlined',
  grayInfo: 'gray-info',
  violetInfo: 'violet-info',
  danger: 'danger',
  countdown: 'countdown',
  lock: 'lock',
  upgradePlan: 'upgrade-plan',
  yellowInfo: 'yellow-info',
}

export const SENTRY_DSN = 'https://98fc705b97774f73a1f287ff8c9fe411@o1054360.ingest.sentry.io/6040134'
export const SENTRY_ENV = process.env.SENTRY_ENV || process.env.NODE_ENV
export const RELEASE_TAG = process.env.RELEASE_TAG || '1'
export const IS_SENTRY_DISABLED = process.env.NODE_ENV !== 'production' || !process.env.RELEASE_TAG

export const IS_LOCALHOST = process.env.NODE_ENV !== 'production' || !process.env.RELEASE_TAG

export const RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_KEY || '6LdC3QAgAAAAAOE6z--ajujTCQ6wHdyRWcV9GBqD'
export const RECAPTCHA_SCRIPT_URL = `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_SITE_KEY}`

export const MAIN_SITE_LINK = 'https://expertbox.io/'
export const MAIN_SITE_DOMAIN = 'expertbox.io'
export const COOKIES_DOMAIN =
  process.env.NODE_ENV !== 'production' || !process.env.RELEASE_TAG ? 'localhost' : MAIN_SITE_DOMAIN

export const IS_PRODUCTION = process.env.NODE_ENV === 'production'

export const PRIVACY_POLICY_LINK = `${MAIN_SITE_LINK}policies`
export const PRICING_LINK = `${MAIN_SITE_LINK}pricings`
export const TERMS_OF_USE_LINK = `${MAIN_SITE_LINK}terms-of-use`
export const SIDE_WIDGET_HELP_LINK = 'https://help.expertbox.io/article/196-add-expertbox-side-widget-to-your-website'

export const DURATION_REGEX = /(?:^(\d+)h (\d+)m$)|(?:^(\d+)h$)|(?:^(\d+)m$)/
export const HEX_COLOR_REGEX = /^#([A-Fa-f0-9]{6})$/
export const SELECTOR_REGEX = /(#|\.)[_a-zA-Z0-9-]+/
export const WORKSPACE_URL_REGEX = /^[a-z0-9][a-z0-9-]*$/
export const URL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(http|https):\/\/|[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?$/i
// Email regex is taken from yup version 0.32 as it fits more for project needs
export const EMAIL_REGEX =
  // eslint-disable-next-line
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
export const BRAND_NAME = 'ExpertBox Inc.'
export const CONTENT_DISPOSITION_FILENAME_REGEX = /.*filename=(?:['"]?)([^"';]+)(?:['"]?)/
export const LOWERCASE_CHARACTER_REGEX = /[a-z]/
export const UPPERCASE_CHARACTER_REGEX = /[A-Z]/
export const NUMBER_CHARACTER_REGEX = /[\d]/
// eslint-disable-next-line no-useless-escape
export const SPECIAL_CHARACTER_REGEX = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/
export const EMAIL_SPLIT_REGEX = /^([^@]+)@(.+)\.(.+)$/

export const MAX_INPUT_LENGTH = 250
export const MAX_TEXTAREA_CONTENT_LENGTH = 2000
export const MAX_TEXTAREA_CONTENT_NORMAL_LENGTH = 1000
export const MAX_TEXTAREA_CONTENT_SHORT_LENGTH = 255
export const MAX_TEXTAREA_CONTENT_MIDDLE_LENGTH = 500
export const MAX_INPUT_CONTENT_LENGTH = 255
export const MAX_INPUT_NAME_LENGTH = 40
export const MAX_NAME_LASTNAME_LENGTH = 50
export const MAX_ZIPCODE_LENGTH = 10
export const MAX_TAX_ID_LENGTH = 20
export const MAX_PHONE_NUMBER_LENGTH = 15
export const MAX_PASSWORD_LENGTH = 72
export const MAX_EMAIL_LENGTH = 255
export const MIN_PASSWORD_LENGTH = 8 // Some user passwords can still be 6 characters long; use only for create or update
export const LOGIN_CODE_LENGTH = 6
export const CONFIRMATION_CODE_LENGTH = 6

export const DEFAULT_TRIAL_DAYS_NUMBER = 14

export const GOOGLE_PLAY_LINK = '#'
export const APP_STORE_LINK = '#'
export const APP_NAME = 'expertbox'
export const APP_DOMAIN = 'expertbox.io'
export const HTTP_STATUSES = {
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  gone: 410,
  unprocessableEntity: 422,
  tooManyRequests: 429,
}

export const PUBLISHABLE_TEST_API_KEY = 'pk_test_hOaz91LN7kJzbvYqg1vn8xMB'

export const ELEMENTS_OPTIONS = {
  locale: locale.locale,
  fonts: [
    {
      family: 'Inter',
      src: 'url(static/fonts/inter/inter-v13-latin-regular.woff2)',
      display: 'swap',
      weight: 'normal',
    },
  ],
}

// eslint-disable-next-line prefer-destructuring
export const GA_TRACKING_ID = process.env.GA_TRACKING_ID

export const CALENDAR_CONNECTION_STATUSES = {
  pending: 'pending',
  connected: 'connected',
  disconnected: 'disconnected',
  removing: 'removing',
  syncing: 'syncing',
}

export const AVAILABLE_CALENDAR_CONNECTIONS = 6

export const DATA_SCROLL_CONTAINER = 'main'
export const DATA_SCROLL_CONTAINER_CLASS = 'app-wrap__viewport--scroll'
export const DATA_SCROLL_ELEMENT = 'sticky-el'
export const DATA_SCROLL_ELEMENT_CLASS = 'main-page__sticky-el'

export const MAX_FILE_SIZE = 100 * 1024 * 1024
export const MAX_FILES_TO_UPLOAD = 10

export const SORT_TYPES = {
  ascend: 'ascend',
  descend: 'descend',
}

export const BREAKPOINTS = {
  xs: 0,
  smMin: 679,
  sm: 680,
  mdMin: 1023,
  md: 1024,
  lgMin: 1279,
  lg: 1280,
  xl: 1440,
}

export const REFERRER_PAGES = {
  etherpadDocumentEdit: 'etherpadDocumentEdit',
  etherpadDocumentReview: 'etherpadDocumentReview',
}
