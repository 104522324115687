export const IS_OWNER = 'isOwner'
export const IS_ADMIN = 'isAdmin'
export const IS_SCHEDULER = 'isScheduler'
export const IS_ADMIN_OR_OWNER = 'isAdminOrOwner'
export const IS_ADMIN_OR_OWNER_OR_SCHEDULER = 'isAdminOrOwnerOrScheduler'

export const PERMISSIONS = {
  manageAgreements: 'manageAgreements',
  manageBookingPage: 'manageBookingPage',
  manageWaitlist: 'manageWaitlist',
  manageWaitlistSettings: 'manageWaitlistSettings',
  manageBookingPolicy: 'manageBookingPolicy',
  manageBusinessContactDetails: 'manageBusinessContactDetails',
  manageClientFields: 'manageClientFields',
  manageClientPortal: 'manageClientPortal',
  manageCustomNaming: 'manageCustomNaming',
  customizeReceiptTemplate: 'customizeReceiptTemplate',
  manageIntakeForms: 'manageIntakeForms',
  manageLocations: 'manageLocations',
  manageAcceptPayments: 'manageAcceptPayments',
  manageReviewSettings: 'manageReviewSettings',
  manageServices: 'manageServices',
  manageVideoCallSettings: 'manageVideoCallSettings',
  manageSMSNotifications: 'manageSMSNotifications',
  manageWebsite: 'manageWebsite',
  manageTeam: 'manageTeam',
  manageChats: 'manageChats',
  viewSettings: 'viewSettings',
  viewEmployees: 'viewEmployees',
  viewServicesAndCategories: 'viewServicesAndCategories',
  viewCompanySettings: 'viewCompanySettings',
  viewBusinessProfile: 'viewBusinessProfile',
  viewWorkspaceSettings: 'viewWorkspaceSettings',
  viewCompanyPaymentSettings: 'viewCompanyPaymentSettings',
  manageWidget: 'manageWidget',
  connectPaymentMethod: 'connectPaymentMethod',
  editPaymentInformation: 'editPaymentInformation',
  retryPaymentOrRenewSubscription: 'retryPaymentOrRenewSubscription',
  transferOwnership: 'transferOwnership',
  deleteCompany: 'deleteCompany',
  seeConsultantsFilter: 'seeConsultantsFilter',
  applyPromocodes: 'applyPromocodes',
  enableCollectReviews: 'enableCollectReviews',
  publishReviewsOnWidget: 'publishReviewsOnWidget',
  viewCalendarSync: 'viewCalendarSync',
  companyClientsImport: 'companyClientsImport',
  deleteClient: 'deleteClient',
  createClient: 'createClient',
  managePaymentMethods: 'managePaymentMethods',
  manageStripe: 'manageStripe',
  viewInvoiceAndReceiptSettings: 'viewInvoiceAndReceiptSettings',
  manageFeaturesAndIntegrations: 'manageFeaturesAndIntegrations',
  manageFeatures: 'manageFeatures',
  manageTasks: 'manageTasks',
  manageServiceCategories: 'manageServiceCategories',
  requestCustomPayments: 'requestCustomPayments',
  manageAgreementsAndForms: 'manageAgreementsAndForms',
  manageCalendarsIntegrations: 'manageCalendarsIntegrations',
  manageSignatureSettings: 'manageSignatureSettings',
  deleteSubmittedForm: 'deleteSubmittedForm',
  editFormResponses: 'editFormResponses',
  fillOutForm: 'fillOutForm',
  manageMicrosoftTeamsIntegration: 'manageMicrosoftTeamsIntegration',
  deleteAutomationForm: 'deleteAutomationForm',
  manageCustomPayments: 'manageCustomPayments',
  manageRefunds: 'manageRefunds',
  manageFinancialDocumentsFeature: 'manageFinancialDocumentsFeature',
  sendForms: 'sendForms',
  sendAgreements: 'sendAgreements',
  manageCardReaders: 'manageCardReaders',
  manageClientRelationshipTypes: 'manageClientRelationshipTypes',
  manageClientContacts: 'manageClientContacts',
  manageBusinessFiles: 'manageBusinessFiles',
  manageDocumentTemplates: 'manageDocumentTemplates',
  createDocumentFromTemplate: 'createDocumentFromTemplate',
  manageCustomDomain: 'manageCustomDomain',
  manageStorage: 'manageStorage',
  manageClientBusinessAttachments: 'manageClientBusinessAttachments',
}
