import setCookieIsomorphic from 'utils/setCookieIsomorphic'
import { IS_LOCALHOST } from 'lib/constants'

const setEtherpadSession = (ctx, sessionId) => {
  if (!sessionId) {
    return
  }

  setCookieIsomorphic(ctx, 'sessionID', sessionId, {
    path: '/',
    secure: true,
    sameSite: IS_LOCALHOST ? 'none' : 'lax',
  })
}

export default setEtherpadSession
