export const MAX_SENDER_NAME_SIZE = 50

export const MAX_SENDER_EMAIL_SIZE = 200

export const FREE_DOMAINS = [
  'gmail.com',
  'outlook.com',
  'hotmail.com',
  'live.com',
  'msn.com',
  'yahoo.com',
  'yahoo.co.uk',
  'ymail.com',
  'rocketmail.com',
  'icloud.com',
  'me.com',
  'mac.com',
  'protonmail.com',
  'protonmail.ch',
  'zoho.com',
  'aol.com',
  'yandex.com',
  'yandex.ru',
  'mail.com',
  'gmx.com',
  'gmx.de',
]
