import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const connectCustomDomain = makeFormSubmitAction(types.CONNECT_CUSTOM_DOMAIN)

export const disconnectCustomDomain = ({ notificationKind } = {}) => ({
  type: types.DISCONNECT_CUSTOM_DOMAIN,
  notificationKind,
})

export const getCustomDomain = () => ({
  type: types.GET_CUSTOM_DOMAIN,
})

export const setCustomDomain = domain => ({
  type: types.SET_CUSTOM_DOMAIN,
  domain,
})

export const removeCustomDomain = () => ({
  type: types.REMOVE_CUSTOM_DOMAIN,
})
