/* eslint-disable no-template-curly-in-string */
const subscriptionPlans = {
  'subscriptionPlans.title': 'Subscription plans',
  'subscriptionPlans.currentPlan': 'Current plan',
  'subscriptionPlans.trial.progress.remainingDays': 'Remaining days <span>{daysUsed}/{duration}</span>',
  'subscriptionPlans.trial.planName': 'Free {planName} trial',
  'subscriptionPlans.trial.info.planName': 'All benefits of <span>{planName}</span> plan',
  'subscriptionPlans.trial.info.bookings': 'Unlimited bookings',
  'subscriptionPlans.trial.info.payments': 'Available payments',
  'subscriptionPlans.trial.info.currentPlan': 'Current plan',
  'subscriptionPlans.table.annualy': 'Annualy',
  'subscriptionPlans.table.monthly': 'Monthly',
  'subscriptionPlans.table.availablePlans': 'Available plans',
  'subscriptionPlans.table.billingPeriod': 'Billing period',
  'subscriptionPlans.table.feature.services': '_(Services)',
  'subscriptionPlans.table.feature.services.tooltip':
    'Set up _(services) you provide to let _(clients) book _(appointments) via ExpertBox.',
  'subscriptionPlans.table.feature.appointments': '_(Appointments)',
  'subscriptionPlans.table.feature.appointments.tooltip':
    'Schedule _(appointments) with your _(clients) and update or cancel them as needed.',
  'subscriptionPlans.table.feature.recurringAppointments': 'Recurring _(appointments)',
  'subscriptionPlans.table.feature.recurringAppointments.tooltip':
    'Schedule regular _(appointments) to meet with your _(clients) on a regular basis.',
  'subscriptionPlans.table.feature.availability': 'Availability management',
  'subscriptionPlans.table.feature.availability.tooltip':
    "Let _(clients) book available time slots by setting your and your team's working hours.",
  'subscriptionPlans.table.feature.multipleLocations': 'Locations management',
  'subscriptionPlans.table.feature.multipleLocations.tooltip':
    'Offer _(services) and manage availability both online and in physical business locations.',
  'subscriptionPlans.table.feature.calendarIntegration': 'Two-way calendar sync',
  'subscriptionPlans.table.feature.calendarIntegration.tooltip':
    'Sync multiple calendars to avoid double-bookings and manage your availability across platforms.',
  'subscriptionPlans.table.feature.sms': 'SMS reminders',
  'subscriptionPlans.table.feature.sms.tooltip': 'Reduce no-shows with SMS reminders about upcoming _(appointments).',
  'subscriptionPlans.table.feature.email': 'Email & in-app reminders',
  'subscriptionPlans.table.feature.email.tooltip':
    'Send automated reminders for _(experts) and _(clients) and get notified about any changes related to your _(appointments).',
  'subscriptionPlans.table.feature.bookingPage': 'Booking pages',
  'subscriptionPlans.table.feature.bookingPage.tooltip': 'Share booking links with _(clients) and get booked online.',
  'subscriptionPlans.table.feature.bookingWidgets': 'Booking widget',
  'subscriptionPlans.table.feature.bookingWidgets.tooltip':
    'Integrate a booking page into your website as a button or embedded panel.',
  'subscriptionPlans.table.feature.website': 'Website',
  'subscriptionPlans.table.feature.website.tooltip':
    'Establish online presence and set up a professional-looking booking website with minimum effort — no programming skills required.',
  'subscriptionPlans.table.feature.waitlist': 'Waitlist',
  'subscriptionPlans.table.feature.waitlist.tooltip':
    'Quickly fill openings and enhance _(client) satisfaction with an easy-to-use waitlist system.',
  'subscriptionPlans.table.feature.reviews': 'Public reviews',
  'subscriptionPlans.table.feature.reviews.tooltip':
    'Collect and publish _(client) reviews on your booking pages to build trust and provide social proof of your _(services).',
  'subscriptionPlans.table.feature.acceptVideoCalls': 'Accept video calls',
  'subscriptionPlans.table.feature.acceptVideoCalls.tooltip':
    'Have high-quality video meetings with _(clients) using your preferred video conferencing tool: ExpertBox, Zoom, or Google Meet.',
  'subscriptionPlans.table.feature.groupVideoCalls': 'Group video calling hours',
  'subscriptionPlans.table.feature.groupVideoCalls.tooltip':
    'Have group meetings online inside your workspace, inviting up to 12 people to join a video call.',
  'subscriptionPlans.table.feature.videoCallRecording': 'Video recording',
  'subscriptionPlans.table.feature.videoCallRecording.tooltip':
    'Record ExpertBox video calls to share with _(clients) or review them whenever needed.',
  'subscriptionPlans.table.feature.teamMembers': 'Team members',
  'subscriptionPlans.table.feature.teamMembers.tooltip': 'Invite _(experts) to join your team via email',
  'subscriptionPlans.table.feature.team': 'Team management',
  'subscriptionPlans.table.feature.team.tooltip':
    'Set team roles and permissions, work hours, assign _(services), track team performance, and more.',
  'subscriptionPlans.table.feature.storage': 'Workspace storage space',
  'subscriptionPlans.table.feature.storage.tooltip':
    'Get the storage space to upload more files and documents and store video recordings.',
  'subscriptionPlans.table.feature.customDomains': 'Custom workspace domain',
  'subscriptionPlans.table.feature.customDomains.tooltip':
    'Enhance your professional presence by connecting a custom domain to your ExpertBox platform.',
  'subscriptionPlans.table.feature.customNaming': 'Custom naming',
  'subscriptionPlans.table.feature.customNaming.tooltip':
    'Customize key terminology across your workspace to speak the same language as your _(clients).',
  'subscriptionPlans.table.feature.crm': '_(Client) CRM',
  'subscriptionPlans.table.feature.crm.tooltip':
    'Manage all your _(client) information, activity, and booking history to build strong relationships with your _(clients).',
  'subscriptionPlans.table.feature.clientContacts': '_(Client) Contacts',
  'subscriptionPlans.table.feature.clientContacts.tooltip':
    'Create _(client) contacts for streamlined coordination, billing, and communication.',
  'subscriptionPlans.table.feature.clientPortal': '_(Client) Portal',
  'subscriptionPlans.table.feature.clientPortal.tooltip':
    'Let _(clients) book, pay for _(appointments), securely communicate and exchange documents in a single place.',
  'subscriptionPlans.table.feature.chat': 'Chats',
  'subscriptionPlans.table.feature.chat.tooltip':
    'Provide instant support to _(clients) with a secure chat that complies with GDPR and HIPAA requirements.',
  'subscriptionPlans.table.feature.forms': '_(Client) forms',
  'subscriptionPlans.table.feature.forms.tooltip': 'Collect information from _(clients) to provide better _(services).',
  'subscriptionPlans.table.feature.textDocuments': 'Documents and files',
  'subscriptionPlans.table.feature.textDocuments.tooltip':
    'Eliminate paperwork and securely send legal documents and flies to _(clients), getting necessary signatures.',
  'subscriptionPlans.table.feature.documentTemplates': 'Document templates',
  'subscriptionPlans.table.feature.documentTemplates.tooltip': 'Add templates to generate documents in a few clicks.',
  'subscriptionPlans.table.feature.signature': 'E-signature',
  'subscriptionPlans.table.feature.signature.tooltip':
    'Configure audit trail and signature disclosure for your documents.',
  'subscriptionPlans.table.feature.voiceMessage': 'Voice messages',
  'subscriptionPlans.table.feature.voiceMessage.tooltip':
    'Efficiently connect with team and _(clients) through quick voice messages.',
  'subscriptionPlans.table.feature.acceptPayments': 'Accept payments',
  'subscriptionPlans.table.feature.acceptPayments.tooltip':
    'Accept online payments through ExpertBox and manage all transactions in one place.',
  'subscriptionPlans.table.feature.customPayments': 'Recurring payments',
  'subscriptionPlans.table.feature.customPayments.tooltip': 'Collect recurring payments for your _(services).',
  'subscriptionPlans.table.feature.stripePos': 'Stripe Point of Sale',
  'subscriptionPlans.table.feature.stripePos.tooltip': 'Accept in-person payments using a Stripe card reader.',
  'subscriptionPlans.table.feature.financialDocuments': 'Financial documents',
  'subscriptionPlans.table.feature.financialDocuments.tooltip':
    'Automate invoice and receipt generation and download them as needed.',
  'subscriptionPlans.table.feature.refunds': 'Refunds',
  'subscriptionPlans.table.feature.refunds.tooltip':
    'Set a clear refund policy and let ExpertBox automatically process your refunds.',
  'subscriptionPlans.table.feature.businessFiles': 'Business files',
  'subscriptionPlans.table.feature.businessFiles.tooltip':
    'Store, organize, and share important files for your business seamlessly with ExpertBox.',
  'subscriptionPlans.table.section.scheduling': 'Scheduling',
  'subscriptionPlans.table.section.onlineBooking': 'Online booking',
  'subscriptionPlans.table.section.videoCalling': 'Video calling',
  'subscriptionPlans.table.section.workspaceManagement': 'Workspace management',
  'subscriptionPlans.table.section.fileStorage': 'File storage',
  'subscriptionPlans.table.section.clientCommunication': '_(Client) communication',
  'subscriptionPlans.table.section.billingAndInvoicing': 'Billing & invoicing',
  'subscriptionPlans.selectPlan': 'Select plan',
  'subscriptionPlans.upgradePlan': 'Upgrade plan',
  'subscriptionPlans.downgradePlan': 'Downgrade plan',
  'subscriptionPlans.table.free.title.name': 'Free',
  'subscriptionPlans.table.premium.title.name': 'Premium',
  'subscriptionPlans.table.premium_plus.title.name': 'Premium Plus',
  'subscriptionPlans.perUser': 'per user/month',
  'subscriptionPlans.bestChoice': 'Best choice',
  'subscriptionPlans.table.unlimited': 'Unlimited',
  'subscriptionPlans.table.payAsYouGo': 'Pay as you go',
  'subscriptionPlans.trialIsEnded.message':
    'Your free Premium trial has ended! You are using the free version of ExpertBox. Upgrade now to unlock all the premium features: invite your team, enjoy expanded storage, accept online payments, securely chat and exchange documents with _(clients), and more.',

  'cancelTeamSeatsDowngrade.cancel': 'Cancel request',
  'cancelTeamSeatsDowngrade.cancel?': 'Cancel request?',
  'cancelTeamSeatsDowngrade.restoreSeats':
    'This will restore {teamSeatsDowngradedQuantity} team {teamSeatsDowngradedQuantity, plural, one {seat} other {seats}} to your workspace.',
  'cancelTeamSeatsDowngrade.info':
    '{teamSeatsDowngradedQuantity} team {teamSeatsDowngradedQuantity, plural, one {seat} other {seats}} will be deleted on {paymentDate}. You can’t invite new team members for this seat.',
  'cancelTeamSeatsDowngrade.notAllowedToManageSeats': 'You can’t manage seats',
  'cancelTeamSeatsDowngrade.cancelRequestToContinue':
    "Looks like you've already requested to delete {teamSeatsDowngradedQuantity} {teamSeatsDowngradedQuantity, plural, one {seat} other {seats}} in this billing period. To manage team seats, please cancel your previous request.",
  'cancelTeamSeatsDowngrade.cancelPlanDowngradeToContinue':
    'Once you switch to the Free plan on {switchPlanDate}, all your team members and their seats will be deleted. To manage team seats, please cancel the downgrade.',
  'cancelPlanDowngrade.info':
    'Your {currentPlanName} subscription will be active until {paymentDate}. Starting from that date you will switch to {plannedPlanName} plan.',
  'cancelPlanDowngrade.cancel': 'Cancel downgrade',
  'cancelPlanDowngrade.modal.cancel?': 'Cancel downgrade?',
  'cancelPlanDowngrade.modal.isActive':
    'Your {planName} plan will stay the same, and you will be charged the usual subscription fee on {paymentDate}.',

  'cancelStoragePlanDowngrade.modal.title': 'You can’t manage storage',
  'cancelStoragePlanDowngrade.modal.bodyText':
    'Once you switch to the Free plan on {switchPlanDate}, your workspace storage will be downgraded to {storageCapacity}. To manage storage, please cancel the downgrade.',
}

export default subscriptionPlans
