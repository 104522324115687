const settings = {
  'settings.title': 'Settings',
  'settings.links.business.title': 'Business profile',
  'settings.links.business.description': 'Set your business name, logo and contact info.',
  'settings.links.workspaceSettings.title': 'Workspace settings',
  'settings.links.workspaceSettings.description': 'Update your workspace name and other details.',
  'settings.links.manageTeam.title': 'Team',
  'settings.links.manageTeam.description': 'Invite people to work in your workspace and manage their workload.',
  'settings.links.services.title': '_(Services)',
  'settings.links.services.description': 'Create and edit _(services), booking rules, refund policies, and more.',
  'settings.links.bookingPage.title': 'Booking pages',
  'settings.links.bookingPage.description': 'Set your booking page and share it with _(clients).',
  'settings.links.clientFields.title': '_(Client) fields',
  'settings.links.clientFields.description': 'Add fields to _(client) profiles to gather relevant information.',
  'settings.links.clientRelationshipTypes.title': '_(Client) relationship types',
  'settings.links.clientRelationshipTypes.description':
    'Set relationship types to manage _(client) contacts and their permissions.',
  'settings.links.signatureSettings.title': 'E-signature',
  'settings.links.signatureSettings.description': 'Configure audit trail and signature disclosure for your documents.',
  'settings.links.forms.title': 'Forms',
  'settings.links.forms.description': 'Create questionnaires for _(clients) to complete before _(appointments).',
  'settings.links.serviceAgreements.title': '_(Service agreements)',
  'settings.links.serviceAgreements.description': 'Create documents to get _(clients)’ consent before _(appointments).',
  'settings.links.paymentSettings.title': 'Payment settings',
  'settings.links.reviewSettings.title': 'Review settings',
  'settings.links.reviewSettings.description': 'Start collecting _(client) reviews about their experience.',
  'settings.links.paymentSettings.description': 'Choose the ways for _(clients) to pay you.',
  'settings.links.invoicesAndReceipts.title': 'Invoices & receipts',
  'settings.links.invoicesAndReceipts.description': 'Customize your invoices and receipts.',
  'settings.links.subscription.title': 'Subscription',
  'settings.links.subscription.description': 'Manage your ExpertBox subscription and billing information.',
  'settings.links.locations.title': 'Locations',
  'settings.links.locations.description': 'Add your business address to offer _(services) offline.',
  'settings.links.videoCallSettings.title': 'Video call settings',
  'settings.links.videoCallSettings.description': 'Select the way you want to connect with your _(clients) online.',
  'settings.links.calendarSync.title': 'Calendar sync',
  'settings.links.calendarSync.description': 'Sync your Google Calendar to avoid double bookings.',
  'settings.links.bookingPolicy.title': 'Booking policy',
  'settings.links.bookingPolicy.description': 'Set cancellation and rescheduling rules for _(appointments).',
  'settings.links.clientPortal.title': '_(Client) portal',
  'settings.links.clientPortal.description':
    'Set a secure portal for your _(clients) to manage their _(appointments), etc.',
  'settings.links.customNaming.title': 'Custom naming',
  'settings.links.customNaming.description': 'Start using terminology that fits your business.',
  'settings.links.storage.title': 'Storage',
  'settings.links.storage.description': 'Check how much storage you’re using and free up space.',
  'settings.links.smsNotifications.title': 'SMS notifications',
  'settings.links.smsNotifications.description': 'Send SMS notifications and reminders to _(experts) and _(clients).',
  'settings.links.website.title': 'Website',
  'settings.links.website.description': 'Get more bookings by setting up a professional-looking website.',
  'settings.links.waitlist.title': 'Waitlist settings',
  'settings.links.waitlist.description': 'Add _(clients) to the waitlist to track suitable times for _(appointments).',
  'settings.links.documentTemplates.title': 'Document templates',
  'settings.links.documentTemplates.description': 'Add templates to generate documents in a few clicks.',
  'settings.links.customDomain.title': 'Domains',
  'settings.links.customDomain.description': 'Connect and manage your workspace and email domains.',
}

export default settings
