const notifications = {
  'notifications.emailUpdatedConfirmIt': 'Request to confirm your new email was sent',
  'notifications.updateGeneralSettings': 'Account settings were updated.',
  'notifications.employeeAccount.passwordUpdated': 'Your password was updated',
  'notifications.resetPasswordSuccess': 'Your password has been updated',
  'notifications.companyProfileUpdated': 'Business information was updated',
  'notifications.workspaceHasBeenDeleted': 'Workspace has been deleted',
  'notifications.workspaceTwoFactorSettingsUpdated': 'The workspace security settings were updated.',
  'notifications.membersInvited': 'New team members have been invited',
  'notifications.membeIsNoLongerAvailable': 'This team member is no longer available.',
  'notifications.memberDeactivated': 'Team member has been deactivated',
  'notifications.memberActivated': 'Team member has been activated',
  'notifications.memberRemoved': 'Team member has been removed',
  'notifications.employeeInvitationRemoved': 'Invitation has been removed',
  'notifications.companyLogoRemoved': 'Company logo has been removed',
  'notifications.companyLogoUpdated': 'Company logo has been updated',
  'notifications.employeeInvitationResented': 'Invitation has been resent',
  'notifications.employeeProfileChanged': 'The team member’s profile was updated.',
  'notifications.employeeRoleChanged': 'The team member’s role was updated.',
  'notifications.resetTwoFactorAuth': 'Two-step verification is disabled for {name}.',
  'notifications.ownershipTransferred': 'Company ownership has been transferred',
  'notifications.emailHasBeenConfirmed': 'Your new email address has been confirmed',
  'notifications.servicesActivated':
    '{count, plural, one {_(Service)} other {_(Services)}} {count, plural, one {has} other {have}} been activated',
  'notifications.servicesDeactivated':
    '{count, plural, one {_(Service)} other {_(Services)}} {count, plural, one {has} other {have}} been deactivated',
  'notifications.createService.nameIsAlreadyUsed': '_(Service) name is already used',
  'notifications.serviceCreated': '_(Service) has been created',
  'notifications.serviceUpdated': '_(Service) has been updated',
  'notifications.servicesDeleted':
    '{count, plural, one {_(Service)} other {_(Services)}} {count, plural, one {has} other {have}} been deleted',
  'notifications.serviceCategoriesActivated':
    '{count, plural, one {Category} other {Categories}} {count, plural, one {has} other {have}} been activated',
  'notifications.serviceCategoriesDeactivated':
    '{count, plural, one {Category} other {Categories}} {count, plural, one {has} other {have}} been deactivated',
  'notifications.serviceCategoriesCreated': 'Category has been created',
  'notifications.serviceCategoriesUpdated': 'Category has been updated',
  'notifications.serviceCategoriesRemoved':
    '{count, plural, one {Category} other {Categories}} {count, plural, one {has} other {have}} been removed',
  'notifications.invalidLink': 'Link is invalid',
  'notifications.employeeProfileUpdated': 'Personal profile was updated.',
  'notifications.profileHasBeenUpdated': 'Profile has been updated',
  'notifications.documentTemplateWasRenamed': 'The document template name was updated.',
  'notifications.documentTemplateWasDeleted': 'The document template was deleted.',
  'notifications.documentTemplateWasDeletedByAnotherUser': 'The document template you’re trying to access was deleted.',
  'notifications.etherpadDocumentWasRenamed': 'The document name was updated.',
  'notifications.etherpadDocumentWasVoided': 'You voided the document.',
  'notifications.etherpadDocumentWasSigned': 'Thank you for signing the document.',
  'notifications.etherpadDocumentIsNotSigned': 'This document doesn’t require your signature anymore.',
  'notifications.etherpadDocumentNoPermission': 'You no longer have permission to sign documents',
  'notifications.documentTemplateHasBeenPublished':
    'The latest version of this document template has already been published.',
  'notifications.etherpadDocumentRemindersWereSent': 'You sent reminders to sign the document.',
  'notifications.etherpadDocumentAlreadySentForSignature': 'Someone else has already sent this document for signature.',
  'notifications.etherpadDocumentWasSharedWithClient': 'The document was shared with the _(client).',
  'notifications.etherpadDocumentClientAccessWasUpdated': '_(Client) access was updated.',
  'notifications.etherpadDocumentClientAccessWasRemoved': '_(Client) access was removed.',
  'notifications.etherpadDocumentWasDeleted': 'The document was deleted.',
  'notifications.teamMembersAssigned':
    '{count, number} {count, plural, one {team member has} other {team members have}} been assigned',
  'notifications.regularAvailabilityUpdated': 'Regular availability has been updated',
  'notifications.dateAvailabilityUpdated': 'Day-specific availability has been updated',
  'notifications.paymentSettingsDisconnected': 'Stripe was disconnected.',
  'notifications.bookingRescheduled': '_(Appointment) has been rescheduled',
  'notifications.bookingCancelled': '_(Appointment) has been cancelled',
  'notifications.recurringBookingsCancelled': 'The recurring _(appointments) were cancelled.',
  'notifications.clientProfileUpdated': '_(Client) profile has been updated',
  'notifications.copyToClipboard': 'Copied to clipboard',
  'notifications.bookingPageWasUpdated': 'Booking page was updated',
  'notifications.bookingPageHasBeenCreated': 'Booking was created',
  'notifications.bookingPageHasBeenUpdated': 'Booking page settings were updated.',
  'notifications.instructionsHaveBeenSent': 'Email instructions were sent.',
  'notifications.bookingPageHasBeenRemoved': 'Booking page has been removed',
  'notifications.quickLinkWasCopied': 'Link copied to clipboard',
  'notifications.chatHasBeenCreated': 'Chat has been created',
  'notifications.chatHasBeenUpdated': 'Chat has been updated',
  'notifications.chatHasBeenDeleted': 'Chat was deleted',
  'notifications.chatMemberHasBeenRemoved': 'Member has been removed from chat',
  'notifications.emailConfirmationResented': 'Email confirmation has been resent',
  'notifications.fileHasBeenDeleted': 'File has been deleted',
  'notifications.recordingWasDeleted': 'Recording was deleted',
  'notifications.chatHistoryWasDeleted': 'Chat history was deleted.',
  'notifications.videoRecordHasBeenShared': 'Video record has been shared',
  'notifications.receiptTemplateUpdated': 'Invoices & receipts settings were updated.',
  'notifications.extraBillingInformationHasBeenAdded': 'Invoice details were added',
  'notifications.extraBillingInformationHasBeenChanged': 'You updated your invoice details.',
  'notifications.extraBillingInformationHasBeenRemoved': 'You deleted your invoice details.',
  'notifications.confirmationLinkHasBeenExpired': 'Confirmation link has been expired',
  'notifications.signatureSettingsUpdated': 'eSignature settings were updated.',
  'notifications.videocallHoursAboutToExpire':
    'Videocall hours is about to expire. Additional hours billed from $1 per hour thereafter. Upgrade plan to get more hours.',
  'notifications.youHaveUsedAllVideocallHours':
    'You have used {hours} videocall hours. You can upgrade your plan to get more hours. Additional hours billed from $1 per hour thereafter. Upgrade plan to get more hours.',
  'notifications.seatsHasBeenIncreased': 'Your workspace has {newSeats} team seats now! {link}.',
  'notifications.seatsHasBeenIncreased.link': 'Invite team members',
  'notifications.seatsHasBeenDecreased': 'Your workspace has {newSeats} team seats now',
  'notifications.clientAccountDeleted': 'Account has been deleted',
  'notifications.clientСontactAccountDeleted': 'Your access was removed.',
  'notifications.trialAboutToExpire': 'Trial period is about to expire on {date}',
  'notifications.trialHasBeenEnded':
    'Trial period has been ended. You can select a subscription plan to use ExpertBox.',
  'notifications.startGettingPaidPayments':
    'To start getting online payments connect online payment methods in your {paymentSettings}.',
  'notifications.paymentSettings': 'Payment settings',
  'notifications.planSubscriptionUpdated': 'Subscription to plan has been updated',
  'notifications.storageSubscriptionUpdated': 'Subscription to storage plan has been updated',
  'notifications.storageSubscriptionAutoUpgradeEnabled':
    'Your storage will be auto-upgraded when you will be out of space.',
  'notifications.storageSubscriptionAutoUpgradeDisabled': 'Your storage will not be auto-upgraded.',
  'notifications.storageSubscriptionCancelled': 'Your storage plan will be cancelled on {date}.',
  'notifications.storageSubscriptionUpgraded': 'You successfully updated your storage plan to {size}.',
  'notifications.storageSubscriptionDowngraded':
    'Starting from {date}, you’ll have {size} of additional workspace storage.',
  'notifications.paymentMethodHasBeenAdded': 'Billing method was added',
  'notifications.paymentMethodHasBeenChanged': 'You updated your payment method.',
  'notifications.billingAddressUpdated': 'You updated your billing address.',
  'notifications.pleaseCompleteYourBillingInfo': 'Please complete your billing information',
  'notifications.notificationSettingsHaveBeenUpdated': 'Notification settings have been updated',
  'notifications.workspaceNameUpdated': 'Workspace name was updated',
  'notifications.automaticLogoutEnabled': 'Automatic log out is enabled',
  'notifications.automaticLogoutDisabled': 'Automatic log out is disabled',
  'notifications.automaticLogoutProcessed': 'You have been logged out due to inactivity. Please log in again',
  'notifications.calendarAdded': 'The calendar was connected.',
  'notifications.calendarRemoved': 'The calendar was disconnected.',
  'notifications.failedToAddCalendar': 'Failed to add calendar. Try again later',
  'notifications.calendarReconnect.success': 'Calendar has been successfully reconnected',
  'notifications.googleCalendarReconnect.tryAgainLater': 'Failed to reconnect calendar. Try again later',
  'notifications.googleCalendarReconnect.invalidAccount': 'Wrong account selected. To reconnect, please select {name}',
  'notifications.workspaceDeleted': 'Workspace has been deleted',
  'notifications.promocodeAdded': 'Promo code has been added',
  'notifications.invalidCodes': 'Promo code <b>{code}</b> has not been added',
  'notifications.trialPromocodeApplied': 'Your free trial was extended until {date}',
  'notifications.discountPromocodeApplied': '{percentage}% discount has been applied to the next billing period',
  'notifications.invitationsHaveBeenSent': 'Invitations have been sent',
  'notifications.chatMemberAdded': 'Members have been added to chat',
  'notifications.youHaveBeenUnsubscribed': 'You have been unsubscribed',
  'notifications.youHaveBeenSubscribed': 'You have been subscribed',
  'notifications.customFieldWasCreated': 'Custom field was created',
  'notifications.customFieldWasUpdated': 'Custom field was updated',
  'notifications.customFieldWasDeleted': 'Custom field was deleted',
  'notifications.clientInformationWasUpdated': '_(Client) information was updated.',
  'notifications.clientNoteWasDeleted': 'The note was deleted.',
  'notifications.noteAdded': 'The note was added.',
  'notifications.noteUpdated': 'The note was saved.',
  'notifications.taskWasDeleted': 'The task was deleted.',
  'notifications.accessibilityUpdated.personal': 'The note is private. Your _(client) won’t see it anymore.',
  'notifications.accessibilityUpdated.shared': 'The note was shared with _(client).',
  'notifications.clientAttachmentWasDeleted': 'The file was deleted.',
  'notifications.clientAttachmentWithBusinessFileWasRemoved': 'The file was removed from the _(client) profile.',
  'notifications.clientProfileIsDeleted': '_(Client) profile is deleted.',
  'notifications.clientInvitationToClientPortalWasSent': 'Invitation was sent.',
  'notifications.clientInvitationToClientPortalWasResent': 'Invitation was resent.',
  'notifications.intakeFormCreated': 'The form was created.',
  'notifications.intakeFormUpdated': 'The form was updated.',
  'notifications.intakeFormDeleted': 'The form was deleted.',
  'notifications.intakeFormWasSubmitted': 'The form was submitted',
  'notifications.intakeFormActivated': 'The form was activated.',
  'notifications.intakeFormDeactivated': 'The form was deactivated.',
  'notifications.intakeFormCopied': 'The form copy was created.',
  'notifications.agreementFormWasSubmitted': 'The _(service agreement) was signed',
  'notifications.agreementCreated': 'The _(service agreement) was created.',
  'notifications.agreementUpdated': 'The _(service agreement) was updated.',
  'notifications.agreementDeleted': 'The _(service agreement) was deleted.',
  'notifications.agreementActivated': 'The _(service agreement) was activated.',
  'notifications.agreementDeactivated': 'The _(service agreement) was deactivated.',
  'notifications.appointmentWasRescheduled': 'The _(appointment) was rescheduled.',
  'notifications.reviewDeleted': 'The review was deleted',
  'notifications.reviewSettingsUpdated': 'Review settings were updated',
  'notifications.reviewWasUpdated': 'The review was updated',
  'notifications.bookingWasAccepted': 'You accepted the _(appointment).',
  'notifications.bookingWasDeclined': 'You declined the _(appointment)',
  'notifications.waitlistBookingWasDeclined': 'You declined _(appointment) and deleted the waitlist request.',
  'notifications.appointmentWasUpdated': 'The _(appointment) was updated.',
  'notifications.appointmentWasScheduled': 'Your _(appointment) was scheduled.',
  'notifications.filesSharedWithClient': 'Files shared with the _(client).',
  'notifications.documentsSentToClient': 'Documents were sent to the _(client).',
  'notifications.clientRelationshipCreated': 'Relationship type was created.',
  'notifications.contactWasCreated': 'The contact was created.',
  'notifications.contactWasUpdated': 'The contact was updated.',
  'notifications.contactWasDeleted': 'The contact was deleted.',
  'notifications.clientRelationshipUpdated': 'Relationship type was updated.',
  'notifications.clientRelationshipDeleted': 'Relationship type was deleted.',
  'notifications.intakeSentToClient': 'The form was shared with the _(client).',
  'notifications.agreementSentToClient': '_(Service agreements) were shared with the _(client).',
  'notifications.locationCreated': 'The location was created.',
  'notifications.newClientWasCreated': 'New _(client) was created.',
  'notifications.manualPaymentWasDisconnected': 'Manual payments method was disconnected.',
  'notifications.manualPaymentWasConnected': 'Manual payments method was connected.',
  'notifications.manualPaymentWasUpdated': 'Payment instructions were updated.',
  'notifications.stripeWasConnected': 'Stripe was connected.',
  'notifications.stripePosWasConnected': 'Stripe Point of Sale was connected.',
  'notifications.stripePosWasDisconnected': 'Stripe Point of Sale was disconnected.',
  'notifications.stripeConnectError': 'Stripe was not connected due to the error. Try again.',
  'notifications.paypalWasConnected': 'PayPal method was connected.',
  'notifications.paypalWasDisconnected': 'PayPal method was disconnected.',
  'notifications.locationHasBeenDeactivated': 'The location was deactivated.',
  'notifications.locationHasBeenActivated': 'The location was activated.',
  'notifications.locationWasUpdated': 'The location was updated.',
  'notifications.locationWasDeleted': 'The location was deleted.',
  'notifications.bookingCompleteError': 'We couldn’t complete the booking because your _(appointment) was updated',
  'notifications.paymentCanceled': 'The payment request was cancelled.',
  'notifications.paymentSend': 'The payment request was sent.',
  'notifications.paymentResend': 'The payment request was resent.',
  'notifications.paymentDraftDeleted': 'The draft payment was deleted.',
  'notifications.payment.paymentMarkedAsPaid': 'The payment was marked as paid.',
  'notifications.refund.paymentMarkedAsPaid': 'The payment was refunded.',
  'notifications.paymentCompleteError': 'Payment was not processed due to some error',
  'notifications.paymentWasPaid': 'Thank you for your payment.',
  'notifications.appointmentWasPaid': '_(Appointment) was paid',
  'notifications.requestPayment': 'The payment request was sent.',
  'notifications.saveAsDraft': 'Payment saved as Draft.',
  'notifications.updatePayment': 'Updated payment request was sent to _(client). ',
  'notifications.updateBookingPolicy': 'Booking policy was updated.',
  'notifications.paymentIssueRefund': 'The refund was issued {link}',
  'notifications.paymentIssueRefund.viewRefund': 'View refund',
  'notifications.paymentMethodWasSaved': 'New payment method was saved',
  'notifications.recurringPaymentSent': 'The payment request was sent.',
  'notifications.recurringPaymentDraftDeleted': 'Draft recurring payment was deleted.',
  'notifications.saveAsDraftRecurring': 'Payment saved as Draft.',
  'notifications.requestRecurringPayment': 'The payment request was sent.',
  'notifications.updateRecurringPayment': 'Recurring payment was updated.',
  'notifications.autoPayWasCancelled': 'Auto-pay was cancelled.',
  'notifications.recurringPaymentCancelled': 'Recurring payment was cancelled.',
  'notifications.clientPortalWasUpdated': '_(Client) portal settings were updated.',
  'notifications.accountSettingsWereUpdated': 'Account settings were updated',
  'notifications.yourProfileImageUpdated': 'Your profile image has been updated',
  'notifications.yourProfileImageRemoved': 'Your profile image has been removed',
  'notifications.websiteSettingsWereUpdated': 'Website settings were updated.',
  'notifications.websiteWasDeleted': 'Website was deleted.',
  'notifications.customNameWasUpdated': 'Custom naming was updated.',
  'notifications.recurringAppointmentsEnabled': 'You enabled recurring bookings by _(experts).',
  'notifications.recurringAppointmentsDisabled': 'You disabled recurring bookings by _(experts).',
  'notifications.clientPortalEnabled': '_(Client) portal feature is enabled.',
  'notifications.clientPortalDisabled': '_(Client) portal feature is disabled.',
  'notifications.clientContactsEnabled': '_(Client) contacts feature is enabled.',
  'notifications.signatureEnabled': 'E-signature feature is enabled.',
  'notifications.clientContactsDisabled': '_(Client) contacts feature is disabled.',
  'notifications.signatureDisabled': 'E-signature feature is disabled.',
  'notifications.serviceCategoriesEnabled': 'You enabled _(service) categories management.',
  'notifications.serviceCategoriesDisabled': 'You disabled _(service) categories management.',
  'notifications.tasksEnabled': 'Tasks feature is enabled.',
  'notifications.tasksDisabled': 'Tasks feature is disabled.',
  'notifications.financialDocumentsEnabled': 'Financial documents feature is enabled.',
  'notifications.financialDocumentsDisabled': 'Financial documents feature is disabled.',
  'notifications.acceptVideoCallsEnabled': 'Accept video calls feature is enabled.',
  'notifications.acceptVideoCallsDisabled': 'Accept video calls feature is disabled.',
  'notifications.groupVideoCallsEnabled': 'ExpertBox group video calls feature is enabled.',
  'notifications.groupVideoCallsDisabled': 'ExpertBox group video calls feature is disabled.',
  'notifications.videoCallRecordingEnabled': 'ExpertBox video calls recording feature is enabled.',
  'notifications.videoCallRecordingDisabled': 'ExpertBox video calls recording feature is disabled.',
  'notifications.websiteEnabled': 'You enabled website.',
  'notifications.websiteDisabled': 'You disabled website.',
  'notifications.multipleLocationsEnabled': 'Offline locations feature is enabled.',
  'notifications.multipleLocationsDisabled': 'Offline locations feature is disabled.',
  'notifications.recurringPaymentsEnabled': 'You enabled recurring bookings by _(experts).',
  'notifications.recurringPaymentsDisabled': 'You disabled recurring bookings by _(experts).',
  'notifications.websiteWasPublished': 'Your website was published',
  'notifications.crmEnabled': 'You enabled CRM.',
  'notifications.crmDisabled': 'You disabled CRM.',
  'notifications.chatEnabled': 'Chat feature is enabled.',
  'notifications.chatDisabled': 'Chat feature is disabled.',
  'notifications.voiceMessageEnabled': 'Voice Messages feature is enabled.',
  'notifications.voiceMessageDisabled': 'Voice Messages feature is disabled.',
  'notifications.acceptPaymentsEnabled': 'You enabled accepting payments.',
  'notifications.acceptPaymentsDisabled': 'You disabled accepting payments.',
  'notifications.customPaymentsEnabled': 'Custom payments feature is enabled.',
  'notifications.customPaymentsDisabled': 'Custom payments feature is disabled.',
  'notifications.refundsEnabled': 'Refunds feature is enabled.',
  'notifications.refundsDisabled': 'Refunds feature is disabled.',
  'notifications.sms.expert': '_(Expert)',
  'notifications.sms.client': '_(Client)',
  'notifications.sms.appointmentNotifications': '_(Appointment) notifications',
  'notifications.sms.appointmentNotifications.description':
    'Choose notifications to send to _(experts) and _(clients). SMS costs vary by country (usually from $0.02 to $0.40 per message) and are charged at the end of the billing period. To learn more, go to',
  'notifications.sms.appointmentReminders': '_(Appointment) reminders',
  'notifications.sms.appointmentReminders.description':
    'Send SMS reminders about upcoming _(appointments). SMS costs vary by country (usually from $0.02 to $0.40 per message) and are charged at the end of the billing period. To learn more, go to',
  'notifications.sms.formNotifications': 'Form notifications',
  'notifications.sms.formNotifications.description':
    'Choose notifications to send to _(experts) and _(clients) when they have a new form. SMS costs vary by country (usually from $0.02 to $0.40 per message) and are charged at the end of the billing period. To learn more, go to',
  'notifications.sms.formReminders': 'Form reminders',
  'notifications.sms.formReminders.description':
    'Send SMS reminders to _(experts) and _(clients) when they have pending forms. SMS costs vary by country (usually from $0.02 to $0.40 per message) and are charged at the end of the billing period. To learn more, go to',
  'notifications.sms.subscriptionSettings': 'Subscription settings.',
  'notifications.sms.updated': 'SMS notifications were updated',
  'notifications.sms.type.iHaveANewAppointmentBooked': 'I have a new _(appointment) booked',
  'notifications.sms.type.myAppointmentHasBeenAcceptedOrDeclinedByClient':
    'My invitation to _(appointment) has been accepted or declined by _(Client)',
  'notifications.sms.type.myAppointmentHasBeenCancelled': 'My _(appointment) is cancelled',
  'notifications.sms.type.myAppointmentHasBeenRescheduled': 'My _(appointment) is rescheduled',
  'notifications.sms.type.appointmentCreated': 'My _(appointment) is requested',
  'notifications.sms.type.appointmentInDay': 'My _(appointment) starts in a day',
  'notifications.sms.type.appointmentIn30Mins': 'My _(appointment) starts in 30 minutes',
  'notifications.sms.type.appointmentStartsNow': 'My _(appointment) starts now',
  'notifications.sms.type.iHaveANewFormToFillOut': 'I have a new form',
  'notifications.sms.type.myFormIsDeclined': 'My form is declined',
  'notifications.sms.type.unfilledFormsReminder': 'Remind about unfilled forms every week',
  'notifications.clientNotificationSettingsUpdated': 'Notifications settings were updated.',
  'notifications.smsEnabled': 'SMS reminders & notifications feature is enabled.',
  'notifications.smsDisabled': 'SMS reminders & notifications feature is disabled.',
  'notifications.cancelTeamSeatsDowngrade.downgradeIsCancelled':
    'Your workspace has {totalQuantity} team {totalQuantity, plural, one {seat} other {seats}}',
  'notifications.reviewsEnabled': 'Reviews feature is enabled.',
  'notifications.reviewsDisabled': 'Reviews feature is disabled.',
  'notifications.agreementsEnabled': '_(Service agreements) feature is enabled.',
  'notifications.agreementsDisabled': '_(Service agreements) feature is disabled.',
  'notifications.textDocumentsEnabled': 'Documents feature is enabled.',
  'notifications.textDocumentsDisabled': 'Documents feature is disabled.',
  'notifications.documentTemplatesEnabled': 'Document templates feature is enabled.',
  'notifications.documentTemplatesDisabled': 'Document templates feature is disabled.',
  'notifications.documentTemplateDeactivated': 'The document template was deactivated.',
  'notifications.documentTemplateActivated': 'The document template was activated.',
  'notifications.documentTemplatePublished': 'The latest version of this document template was published.',
  'notifications.documentTemplateDiscarded': 'The latest published version of this document template was restored.',
  'notifications.formsEnabled': 'Forms feature is enabled.',
  'notifications.formsDisabled': 'Forms feature is disabled.',
  'notifications.subscriptionSettings': 'Subscription settings',
  'notifications.billing.cancelDowngradeStorage.success': 'Your {size} storage plan is restored',
  'notifications.subscriptionUpgraded': 'Welcome to ExpertBox {planName}!',
  'notifications.subscriptionDowngradedToFreeInTrial': 'You switched to {planName}.',
  'notifications.subscriptionDowngraded':
    'You switched to {planName}. Your {currentPlanName} subscription is active until {paymentDate}.',
  'notifications.switchBillingCycle.switched': 'You switched to {newBillingCycleType} billing',
  'notifications.yourDowngradeIsCancelled': 'Your downgrade is cancelled',
  'notifications.clientExpertsWasUnassigned':
    "{users} weren't unassigned because they have upcoming _(appointments) with this _(client).",
  'notifications.calendarIntegrationDisabled': 'Calendar integrations feature is disabled.',
  'notifications.calendarIntegrationEnabled': 'Calendar integrations feature is enabled.',
  'notifications.calendarIntegrationUpdated': 'Calendar integrations were updated.',
  'notifications.waitlistEnabled': 'Waitlist feature is enabled.',
  'notifications.waitlistDisabled': 'Waitlist feature is disabled.',
  'notifications.waitlistSettingsUpdated': 'Waitlist settings were updated.',
  'notifications.formAddedToClient': 'The form was added to the profile.',
  'notifications.formResponsesUpdated': 'Form responses were updated.',
  'notifications.applyDiscount.isNotApplied': 'Promocode wasn’t applied',
  'notifications.retryPayment.paymentWasFailed': 'Payment was failed',
  'notifications.retryPayment.paymentWasSuccessful': 'Thank you for your payment!',
  'notifications.videoCallSettingsUpdated': 'Video call settings were updated',
  'notifications.googleVideoProviderConnected': 'You connected Google Meet',
  'notifications.googleVideoProviderConnectionFailed': 'Couldn’t connect your Google Calendar account. Try again',
  'notifications.googleVideoProviderConnectionFailed.notDefaultIntegrationType':
    'Google Meet is not set as default video call provider',
  'notifications.googleVideoProviderConnectionFailed.noCalendarSync':
    'Google Meet requires a connect Google Calendar account',
  'notifications.googleVideoProviderDisconnected': 'You disconnected Google Meet',
  'notifications.microsoftTeamsConnectionSuccess': 'You connected Microsoft Teams to your workspace',
  'notifications.microsoftTeamsConnectionFailed': 'Couldn’t connect your Microsoft Teams account. Try again',
  'notifications.microsoftTeamsDisconnected': 'You disconnected Microsoft Teams',
  'notifications.zoomConnectionSuccess': 'You connected Zoom',
  'notifications.zoomConnectionFailed': 'Couldn’t connect your Zoom account. Try again',
  'notifications.zoomDisconnected': 'You disconnected Zoom',
  'notifications.invitationsWereSent':
    '{count, plural, one {Invitation} other {Invitations}} {count, plural, one {was} other {were}} sent.',
  'notifications.memberWasRemoved': 'The member was removed from the _(appointment).',

  'notifications.videoCall.youRemoveUser': 'You removed {userName} from the call',
  'notifications.videoCall.userMutedYou': '{userName} muted you for everyone on the call',
  'notifications.videoCall.youHaveBeenRemoved': 'You have been removed from the call by {userName}',
  'notifications.videoCall.userHasBeenRemoved': '{userName} has been removed',

  'notifications.paymentWasSuccessful': 'The payment was successful.',

  'notifications.customDomainDisconnected': 'Custom workspace domain was disconnected.',
  'notifications.customDomainConnectionStopped': 'The connection process has been stopped.',
  'notifications.customEmailDomainDisconnected': 'Custom email domain was disconnected.',
  'notifications.customEmailDomainConnectionStopped': 'The connection process has been stopped.',
  'notifications.stripePosDisabled': 'Stripe Point of Sale feature is disabled.',
  'notifications.stripePosEnabled': 'Stripe Point of Sale feature is enabled.',
  'notifications.employeeTwoFactorSettingsEnabled': 'Two-step verification is enabled.',
  'notifications.employeeTwoFactorSettingsDisabled': 'Two-step verification is disabled.',
  'notifications.employeeTwoFactorSettingsUpdated': 'Two-step verification method was updated.',
  'notifications.formCategoryCreated': 'The category was created.',
  'notifications.formCategoryUpdated': 'The category was updated.',
  'notifications.formCategoryActivated': 'The category was activated.',
  'notifications.formCategoryDeactivated': 'The category was deactivated.',
  'notifications.formCategoryDeleted': 'The category was deleted.',
  'notifications.accessRestored': 'Your access was restored.',
  'notifications.updateCalendarViewSetting': 'Your color code for _(appointments) was changed.',
  'notifications.documentWasSentForSignatures': 'The document was sent for signatures.',
  'notifications.signersForDocumentUpdated': 'Signers for this document are updated.',
  'notifications.documentDeclined': 'You declined to sign the document.',
  'notifications.documentCopyWasCreated': 'The document copy was created.',
  'notifications.accessRevoked': 'Your access was revoked.',
  'notifications.accessUpdated': 'Your access was updated.',
  'notifications.clientWaitlistRequestUpdated': 'The waitlist request was updated.',
  'notifications.deleteWaitlistSuccess': 'The waitlist request was deleted.',
  'notifications.waitlistRequestCreated': 'The waitlist request was created.',
  'notifications.documentTemplate.noLongerAvailable': 'The selected template is no longer available.',
  'notifications.businessFilesEnabled': 'Business files feature is enabled.',
  'notifications.businessFilesDisabled': 'Business files feature is disabled.',
  'notifications.businessFilesFolderWasCreated': 'The folder was created.',
  'notifications.myBusinessFileWasCopied': 'The file copy was created.',
  'notifications.fileSharedWithMeWasCopied': 'The file copy was created in the folder “My files”.',
  'notifications.folderWasMovedToTrash': 'The folder was moved to trash.',
  'notifications.fileWasMovedToTrash': 'The file was moved to trash.',
  'notifications.itemsWereMovedToTrash': '{count} items were moved to trash.',
  'notifications.folderWasUpdated': 'The folder name was updated.',
  'notifications.fileWasUpdated': 'The file name was updated.',
  'notifications.fileWasShared': 'Access updated.',
  'notifications.trashedFilesWereRestored': '{amount} files were restored.',
  'notifications.trashedFoldersWereRestored': '{amount} folders were restored.',
  'notifications.trashedItemsWereRestored': '{amount} items were restored.',
  'notifications.trashedFolderWasRestored': 'The folder was restored {link}',
  'notifications.trashedFileWasRestored': 'The file was restored {link}',
  'notifications.showFileLocation': 'Show file location',
  'notifications.showFolderLocation': 'Show folder location',
  'notifications.noPermissionToPerformAction': `You don't have permission to perform this action.`,
  'notifications.businessFilesWereAddedToClient': 'The business files were added to _(client) profile',
  'notifications.businessStorageMoved': 'The {kind} was moved.',
  'notifications.youDoNotHavePermissions': "You don't have permission to perform this action.",
  'notifications.fileAccessWasRevoked': 'The file access was revoked.',
  'notifications.trashedFiles.fileDeleted': 'The file was deleted.',
  'notifications.trashedFiles.folderDeleted': 'The folder was deleted.',
  'notifications.trashedItemsDeleted': '{count} items were deleted.',
  'notifications.storage.fileDeleted': 'The file was deleted.',
  'notifications.storage.itemsDeleted': '{count} items were deleted.',
  'notifications.waitlistRequestUpdated': 'The waitlist request was updated.',
  'notifications.myFileWasDeleted': 'The file was deleted.',
  'notifications.accessWasRemoved': 'Access updated.',
  'notifications.autoUpgradeSettingsUpdated': 'Auto-upgrade settings were updated.',
  'notifications.customDomainsEnabled': 'Custom domain feature is enabled.',
  'notifications.customDomainsDisabled': 'Custom domain feature is disabled.',
}

export default notifications
