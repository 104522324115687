import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { currentWorkspaceIdSelector } from 'state/concepts/session/selectors'
import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { connectCustomEmailDomainEndpoint } from '../endpoints'
import { CONNECT_CUSTOM_EMAIL_DOMAIN } from '../types'

const connectCustomEmailDomainOperation = createLogic({
  type: CONNECT_CUSTOM_EMAIL_DOMAIN,
  latest: true,

  async process({ action: { values, form }, httpClient, getState }, dispatch, done) {
    const state = getState()
    const { senderName, senderEmail } = values
    const { url, endpoint } = connectCustomEmailDomainEndpoint
    const params = {
      sender_email: senderEmail.toLowerCase(),
      sender_name: senderName,
      include: ['dns-records'],
    }
    const workspaceId = currentWorkspaceIdSelector(state)

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.post(url, params)
      const response = normalize(data, { endpoint })
      const workspaceResponse = updateDataHelper(state.data, 'workspace', workspaceId, {
        relationships: {
          customEmailDomain: {
            data: {
              id: data.data.id,
              type: 'customEmailDomain',
            },
          },
        },
      })
      const fullResponse = { ...response, ...workspaceResponse }

      dispatch(dataApiSuccess({ response: fullResponse, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
    }
    form.setSubmitting(false)
    done()
  },
})

export default connectCustomEmailDomainOperation
