import classNames from 'clsx'
import PropTypes from 'prop-types'

import { ALERT_KINDS } from 'lib/constants'
import Button from 'views/shared/Button'
import Link from 'views/shared/Link'
import AlertIcon from 'views/shared/icons/AlertIcon'
import AlertO from 'views/shared/icons/AlertO'
import Close from 'views/shared/icons/Close'
import Cross from 'views/shared/icons/Cross'
import Info from 'views/shared/icons/Info'
import LockAlt from 'views/shared/icons/LockAlt'
import Tick from 'views/shared/icons/Tick'
import Time from 'views/shared/icons/Time'
import UpgradePlan from 'views/shared/icons/UpgradeStars'
import isPresent from 'utils/isPresent'
import InfoO from 'views/shared/icons/InfoO'
import FormattedOrRawMessage from '../FormattedOrRawMessage'

const ICON_COMPONENTS = {
  [ALERT_KINDS.error]: Close,
  [ALERT_KINDS.warning]: AlertIcon,
  [ALERT_KINDS.warningInfo]: AlertO,
  [ALERT_KINDS.success]: Tick,
  [ALERT_KINDS.videocall]: Tick,
  [ALERT_KINDS.videocallInfo]: Info,
  [ALERT_KINDS.info]: Info,
  [ALERT_KINDS.grayInfo]: Info,
  [ALERT_KINDS.violetInfo]: Info,
  [ALERT_KINDS.countdown]: Time,
  [ALERT_KINDS.lock]: LockAlt,
  [ALERT_KINDS.upgradePlan]: UpgradePlan,
  [ALERT_KINDS.yellowInfo]: Info,
  [ALERT_KINDS.yellowInfoOutlined]: InfoO,
  [ALERT_KINDS.infoOutlined]: InfoO,
}

const Alert = ({
  children,
  className,
  type,
  hasNoIcon,
  message,
  isDiscardable,
  withButton,
  buttonText,
  kind,
  onDiscard,
  onClick,
  isLoading,
  iconSize,
  buttonIcon,
  buttonIconSize,
  buttonIconWrapWithTextClassName,
  buttonMainBtnTxtClassName,
  buttonClassname,
  withButtonInLine,
  selfVerticalCenter,
  withAdditionalButton,
  additionalButtonText,
  additionalButtonClassname,
  href,
  mainAlertIconClassName,
  mainAlertCloseClassName,
  mainAlertContentClassName,
  additionalHref,
}) => {
  const IconComponent = ICON_COMPONENTS[type]

  const alertClassNames = classNames(className, 'main-alert', {
    'main-alert--error': type === ALERT_KINDS.error || type === ALERT_KINDS.lock,
    'main-alert--success': type === ALERT_KINDS.success,
    'main-alert--warning': type === ALERT_KINDS.warning || type === ALERT_KINDS.yellowInfo,
    'main-alert--warning-info': type === ALERT_KINDS.warningInfo,
    'main-alert--info': type === ALERT_KINDS.info || type === ALERT_KINDS.infoOutlined,
    'main-alert--gray-info': type === ALERT_KINDS.grayInfo,
    'main-alert--violet-info': type === ALERT_KINDS.violetInfo || type === ALERT_KINDS.upgradePlan,
    'main-alert--videocall': type === ALERT_KINDS.videocall,
    'main-alert--countdown': type === ALERT_KINDS.countdown,
    'main-alert--videocall-info': type === ALERT_KINDS.videocallInfo,
  })

  return (
    <div className={alertClassNames} data-cy="alert">
      {!hasNoIcon && (
        <span data-cy="main-alert-icon" className={classNames('main-alert__icon', mainAlertIconClassName)}>
          <IconComponent className="fill-currentcolor" size={iconSize} />
        </span>
      )}
      <div
        className={classNames(
          mainAlertContentClassName,
          'main-alert__content',
          { 'main-alert--with-btn-inline': withButtonInLine },
          { 'align-self-center': selfVerticalCenter },
        )}
        data-cy="main-alert-content"
      >
        <div
          className={classNames('main-alert__text', { 'mb-16': withButton && !withButtonInLine })}
          data-cy="main-alert-text"
        >
          <FormattedOrRawMessage message={message} />
          {children}
        </div>
        {/* TODO for front dev do please states disabled, isLoading and onClick */}
        {withAdditionalButton && (
          <Button
            dataCy="main-alert-additional-button"
            text={additionalButtonText}
            onClick={onClick}
            kind={kind}
            size="small"
            isLoading={isLoading}
            disabled={isLoading}
            className={additionalButtonClassname}
            isLink={isPresent(additionalHref)}
            href={additionalHref}
          />
        )}
        {withButton &&
          (href ? (
            <Link href={href}>
              <a data-cy="main-alert-link-with-button" className="d-inline-flex">
                <Button
                  text={buttonText}
                  onClick={onClick}
                  kind={kind}
                  size="small"
                  isLoading={isLoading}
                  disabled={isLoading}
                  dataCy="resend-confirm-link-button"
                  icon={buttonIcon}
                  iconSize={buttonIconSize}
                  iconWrapWithTextClassName={buttonIconWrapWithTextClassName}
                  className={classNames('main-btn--no-hover', buttonClassname)}
                  mainBtnTxtClassName={buttonMainBtnTxtClassName}
                />
              </a>
            </Link>
          ) : (
            <Button
              text={buttonText}
              onClick={onClick}
              kind={kind}
              size="small"
              isLoading={isLoading}
              disabled={isLoading}
              dataCy="resend-confirm-link-button"
              icon={buttonIcon}
              iconSize={buttonIconSize}
              iconWrapWithTextClassName={buttonIconWrapWithTextClassName}
              className={buttonClassname}
              mainBtnTxtClassName={buttonMainBtnTxtClassName}
            />
          ))}
      </div>
      {isDiscardable && (
        <button
          data-cy="main-alert-discard-button"
          className={classNames('main-alert__close', mainAlertCloseClassName)}
          type="button"
          onClick={onDiscard}
        >
          <Cross dataCy="cross-icon" size={12} className="m-8 fill-currentcolor" />
        </button>
      )}
    </div>
  )
}

Alert.defaultProps = {
  children: null,
  message: '',
  className: null,
  hasNoIcon: false,
  type: ALERT_KINDS.error,
  isDiscardable: false,
  withButton: false,
  buttonText: null,
  kind: null,
  onDiscard: null,
  onClick: null,
  isLoading: false,
  iconSize: undefined,
  buttonIcon: undefined,
  buttonIconSize: undefined,
  buttonIconWrapWithTextClassName: null,
  buttonClassname: null,
  withButtonInLine: false,
  selfVerticalCenter: false,
  href: undefined,
  additionalButtonClassname: null,
  withAdditionalButton: false,
  additionalButtonText: null,
  mainAlertIconClassName: null,
  mainAlertCloseClassName: null,
  mainAlertContentClassName: null,
  buttonMainBtnTxtClassName: null,
  additionalHref: null,
}

Alert.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  hasNoIcon: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(ALERT_KINDS)),
  isDiscardable: PropTypes.bool,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      values: PropTypes.shape(),
    }),
  ]),
  buttonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      values: PropTypes.shape(),
    }),
  ]),
  kind: PropTypes.oneOf(['flat', 'outline', 'danger', 'warning', 'videocall', 'lock', 'secondary', null]),
  withButton: PropTypes.bool,
  onDiscard: PropTypes.func,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  iconSize: PropTypes.number,
  buttonIcon: PropTypes.string,
  buttonIconSize: PropTypes.number,
  buttonIconWrapWithTextClassName: PropTypes.string,
  buttonClassname: PropTypes.string,
  withButtonInLine: PropTypes.bool,
  selfVerticalCenter: PropTypes.bool,
  additionalButtonClassname: PropTypes.string,
  withAdditionalButton: PropTypes.bool,
  additionalButtonText: PropTypes.string,
  href: PropTypes.string,
  mainAlertIconClassName: PropTypes.string,
  mainAlertCloseClassName: PropTypes.string,
  mainAlertContentClassName: PropTypes.string,
  buttonMainBtnTxtClassName: PropTypes.string,
  additionalHref: PropTypes.string,
}

export default Alert
