import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { EXCLUDE_WWW_SUBDOMAIN_REGEX } from 'lib/constants/customDomains'
import updateDataHelper from 'utils/updateDataHelper'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { currentWorkspaceIdSelector } from 'state/concepts/session/selectors'
import { setCustomDomain } from '../actions'
import { connectCustomDomainEndpoint } from '../endpoints'
import { CONNECT_CUSTOM_DOMAIN } from '../types'

const connectCustomDomainOperation = createLogic({
  type: CONNECT_CUSTOM_DOMAIN,
  latest: true,

  async process({ action: { values, form }, httpClient, getState }, dispatch, done) {
    const state = getState()
    const { domain } = values
    const { url, endpoint } = connectCustomDomainEndpoint
    const params = { domain: domain.replace(EXCLUDE_WWW_SUBDOMAIN_REGEX, '').toLowerCase(), include: 'dns-records' }
    const workspaceId = currentWorkspaceIdSelector(state)

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.post(url, params)
      const response = normalize(data, { endpoint })
      const workspaceResponse = updateDataHelper(state.data, 'workspace', workspaceId, {
        relationships: {
          customDomain: {
            data: {
              id: data.data.id,
              type: 'customDomain',
            },
          },
        },
      })
      const fullResponse = { ...response, ...workspaceResponse }

      dispatch(dataApiSuccess({ response: fullResponse, endpoint }))
      dispatch(setCustomDomain(domain))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
    }
    form.setSubmitting(false)
    done()
  },
})

export default connectCustomDomainOperation
