/* eslint-disable no-template-curly-in-string */
import { TIME_UNITS_MINUTE, TIME_UNITS_HOUR, TIME_UNITS_DAY, TIME_UNITS_MONTH } from 'lib/constants/timeUnits'

const timeUnitsNamesEn = {
  [TIME_UNITS_MINUTE]: { singular: 'minute', plural: 'minutes' },
  [TIME_UNITS_HOUR]: { singular: 'hour', plural: 'hours' },
  [TIME_UNITS_DAY]: { singular: 'day', plural: 'days' },
  [TIME_UNITS_MONTH]: { singular: 'month', plural: 'months' },
}

const makeDurationUnitError = (messageStart, key) => {
  const unitsOptions = [TIME_UNITS_MINUTE, TIME_UNITS_HOUR, TIME_UNITS_DAY, TIME_UNITS_MONTH].map(
    unit =>
      ` ${unit} {{${key}, plural, one {${timeUnitsNamesEn[unit].singular}} other {${timeUnitsNamesEn[unit].plural}}}}`,
  )
  return `${messageStart} {${key}} {unit, select,${unitsOptions.join(' ')} other {}}`
}

const yup = {
  'yup.mixed.required': "Can't be blank",
  'yup.string.email': 'Please enter your email in the format: name@example.com',
  'yup.string.businessEmail': 'Please use a business email. Free email providers are not allowed.',
  'yup.string.min': 'Should be at least {min} characters long',
  'yup.string.max': 'Can’t be longer than {max} characters',
  'yup.string.businessAddress': 'Please enter your business address',
  'yup.string.businessCity': 'Please enter the city',
  'yup.string.businessCountry': 'Please enter the country',
  'yup.string.businessName': 'Please enter your business name',
  'yup.string.isDurationString': 'Invalid duration format (e.g. 1h 30m)',
  'yup.string.durationMin': 'Must be at least {min} long',
  'yup.string.durationMax': 'Must be equal or less than {max}',
  'yup.string.password.min': '{min} characters minimum',
  'yup.string.password.passwordNameInclusion': 'Must not contain your name or email',
  'yup.string.durationInUnitMax': makeDurationUnitError('Must be equal or less than', 'max'),
  'yup.string.durationInUnitMin': makeDurationUnitError('Must be at least', 'min'),
  'yup.string.phone': 'Is not a valid phone number',
  'yup.number.positive': 'Must be a positive number',
  'yup.number.min': 'Must be greater than or equal to {min}',
  'yup.number.max': 'Must be less than or equal to {max}',
  'yup.number.servicePrice.min': 'Min price is ${min, number, ::.00}',
  'yup.number.servicePrice.max': 'Max price is ${max}',
  'yup.number.integer': 'Must be an integer',
  'yup.number.typeError': 'Must be numeric characters',
  'yup.number.moreThan': 'Price must be equal or greater than zero',
  'yup.number.seats.moreThan': 'Must be greater than or equal to 1',
  'yup.selectAccountOwner': 'You must select a new Account Owner',
  'yup.notIdenticalPasswords': 'New and old password can’t be identical',
  'yup.string.isHexColor': 'Invalid HEX color code',
  'yup.string.isSelector': 'Please, check css selector',
  'yup.string.isWorkspaceUrl': 'Please use only lowercase letters, numbers, and dashes',
  'yup.array.min': 'Must have at least {min} items',
  'yup.string.requiredField': 'This is a required field',
  'yup.mixed.fileType': 'Please upload your file in the {formats} format',
  'yup.mixed.fileSize': "File size can't be bigger than {size}",
  'yup.string.isNotUrl': 'Unfortunately, this can’t be a link',
  'yup.string.rescheduleReason': 'Please tell the _(client) why you need to reschedule',
  'yup.array.isUniqSigner': 'The signer with this email has already been added.',
  'yup.string.password.passwordOneLowercaseLetter': 'One lowercase character (e.g., abcd)',
  'yup.string.password.passwordOneUppercaseLetter': 'One uppercase character (e.g., ABCD)',
  'yup.string.password.passwordOneNumberCharacter': 'One number (e.g., 1234)',
  'yup.string.password.passwordOneSpecialCharacter': 'One special character (e.g., _*&^%$£)',
  'yup.date.endTime.min': 'The end time should be greater than the start time',
}

export default yup
