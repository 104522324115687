import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'
import { FormattedMessage } from 'react-intl'

import Input from 'views/shared/Input'
import InputMessage from 'views/shared/InputMessage'

const InputWithCounterField = ({
  id,
  icon,
  label,
  field,
  maxChars,
  disabled,
  onChange,
  className,
  fieldErrors,
  fieldTouched,
  placeholder,
  dataCy,
  inputClassName,
}) => (
  <div
    className={classNames(className, 'main-input relative', {
      'main-input--disabled': disabled,
      'main-input--has-message': fieldTouched && fieldErrors,
      'main-input--has-message-error': fieldTouched && fieldErrors,
    })}
  >
    {label && (
      <label htmlFor={id} className="main-input__label">
        <FormattedMessage {...label} />
      </label>
    )}
    <Input
      {...field}
      id={id}
      className={inputClassName}
      onChange={onChange}
      placeholder={placeholder}
      dataCy={dataCy}
      disabled={disabled}
    />
    {fieldTouched && fieldErrors && <InputMessage icon={icon} message={fieldErrors} />}
    <p className="in-blue-gray-300 font-12 text-right input-counter" style={fieldErrors && { opacity: 0 }}>
      {field.value ? field.value.length : 0}
      <span className="in-gray-700"> / </span>
      <span className="in-gray-700">{maxChars}</span>
    </p>
  </div>
)

InputWithCounterField.defaultProps = {
  disabled: false,
  label: undefined,
  fieldTouched: false,
  className: undefined,
  fieldErrors: undefined,
  placeholder: null,
  dataCy: 'input-with-counter-field',
  inputClassName: 'mb-4',
}

InputWithCounterField.propTypes = {
  label: PropTypes.shape(),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  fieldTouched: PropTypes.bool,
  id: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  maxChars: PropTypes.number.isRequired,
  fieldErrors: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  field: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  dataCy: PropTypes.string,
}

export default InputWithCounterField
