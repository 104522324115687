export const TRANSACTION_STATUSES = {
  pending: 'pending',
  failed: 'failed',
  paid: 'successful',
}

export const SUBSCRIPTION_PLAN_STATUSES = {
  active: 'active',
  inactive: 'inactive',
}

export const BILLING_PERIOD_STATUSES = {
  open: 'open',
  closed: 'closed',
}

export const DAYS_ABOUT_TO_EXPIRE = 3
export const MAX_USED_CALLS = 100
export const ABOUT_TO_EXPIRE_CALLS = 80

export const PAYMENT_FIELD_ERRORS = {
  cardNumber: { id: 'billing.paymentMethod.validation.cardNumber' },
  cardExpiry: { id: 'billing.paymentMethod.validation.expiryDate' },
  cardCvc: { id: 'billing.paymentMethod.validation.cvc' },
}

export const PLAN_USAGE_KINDS = {
  team: 'team',
  storage: 'storage',
  videoCalls: 'videoCalls',
  videoRecordings: 'videoRecordings',
  smsNotifications: 'smsNotifications',
}

export const PLAN_TYPES = {
  free: 'free',
  premium: 'premium',
  premiumPlus: 'premium_plus',
}

export const PLAN_TYPES_WEIGHT = {
  [PLAN_TYPES.free]: 0,
  [PLAN_TYPES.premium]: 1,
  [PLAN_TYPES.premiumPlus]: 2,
}

export const SUBSCRIPTION_PLANS_SECTIONS = {
  scheduling: 'scheduling',
  onlineBooking: 'onlineBooking',
  videoCalling: 'videoCalling',
  workspaceManagement: 'workspaceManagement',
  fileStorage: 'fileStorage',
  clientCommunication: 'clientCommunication',
  billingAndInvoicing: 'billingAndInvoicing',
}

export const SUBSCRIPTION_PLANS_LAST_FEATURES = {
  email: 'email',
  reviews: 'reviews',
  videoCallRecording: 'videoCallRecording',
  customNaming: 'customNaming',
  textDocuments: 'documentsFiles',
  refunds: 'refunds',
}

export const SUBSCRIPTION_PLANS_FEATURES = [
  { key: SUBSCRIPTION_PLANS_SECTIONS.scheduling },
  {
    key: 'services',
  },
  {
    key: 'appointments',
  },
  {
    key: 'recurringAppointments',
  },
  {
    key: 'availability',
  },
  {
    key: 'multipleLocations',
  },
  {
    key: 'calendarIntegration',
  },
  {
    key: 'sms',
  },
  {
    key: 'email',
  },
  { key: SUBSCRIPTION_PLANS_SECTIONS.onlineBooking },
  {
    key: 'bookingPage',
  },
  {
    key: 'bookingWidgets',
  },
  {
    key: 'website',
  },
  {
    key: 'waitlist',
  },
  {
    key: 'reviews',
  },
  { key: SUBSCRIPTION_PLANS_SECTIONS.videoCalling },
  {
    key: 'acceptVideoCalls',
  },
  {
    key: 'groupVideoCalls',
  },
  {
    key: 'videoCallRecording',
  },
  { key: SUBSCRIPTION_PLANS_SECTIONS.workspaceManagement },
  {
    key: 'teamMembers',
    featureKey: 'team',
  },
  {
    key: 'team',
  },
  {
    key: 'storage',
    skip: true,
  },
  {
    key: 'customDomains',
  },
  {
    key: 'customNaming',
  },
  { key: SUBSCRIPTION_PLANS_SECTIONS.fileStorage },
  {
    key: 'businessFiles',
  },
  { key: SUBSCRIPTION_PLANS_SECTIONS.clientCommunication },
  {
    key: 'crm',
  },
  {
    key: 'clientContacts',
  },
  {
    key: 'clientPortal',
  },
  {
    key: 'chat',
  },
  {
    key: 'voiceMessage',
  },
  {
    key: 'forms',
  },
  {
    key: 'textDocuments',
  },
  {
    key: 'documentTemplates',
  },
  {
    key: 'signature',
  },
  { key: SUBSCRIPTION_PLANS_SECTIONS.billingAndInvoicing },
  {
    key: 'acceptPayments',
  },
  {
    key: 'customPayments',
  },
  {
    key: 'stripePos',
  },
  {
    key: 'financialDocuments',
  },
  {
    key: 'refunds',
  },
]

export const CYCLE_TYPES = {
  annual: 'annual',
  monthly: 'monthly',
}

export const COMPARE_CHANGED_PLAN_FEATURES = [
  { key: 'teamSeats', short: true },
  { key: 'storage', short: true },
  { key: 'calendarIntegration', featureKeys: ['calendar_integration'] },
  { key: 'video', featureKeys: ['group_video_calls', 'video_call_recording'] },
  { key: 'booking', featureKeys: ['booking_widgets', 'website'] },
  { key: 'clientPortal', featureKeys: ['client_portal'] },
  { key: 'chat', featureKeys: ['chat'] },
  { key: 'sms', featureKeys: ['sms'] },
  { key: 'acceptPayments', featureKeys: ['accept_payments'], short: true, onlyPremiumPlus: true },
]

export const TRIAL_EXPIRATION_DAYS = 4
export const MAX_WORKSPACE_TEAM_SEATS_CAPACITY = 1000
export const STORAGE_ABOUT_TO_EXCEED_PERCENTAGE = 80
export const STORAGE_EXCEEDED_PERCENTAGE = 100
