const customDomains = {
  'customDomains.stopConnection.title': 'Stop connection process?',
  'customDomains.stopConnection.description':
    'Your domain will not be connected and the workspace will use default domain.',
  'customDomains.stopConnection.submitText': 'Stop connection process',
  'customDomains.disconnectModal.title': 'Disconnect custom workspace domain?',
  'customDomains.disconnectModal.description': 'From now on, your workspace web address will be restored to default.',
  'customDomains.disconnectModal.submitText': 'Disconnect domain',
  'customDomains.defaultDomain.title': 'Default domain',
  'customDomains.defaultDomain.description':
    'This is your workspace web address. Its subdomain is also used to access your booking pages, website, and _(client) portal.',
  'customDomains.enableFeature':
    'You have the option to connect a custom domain to your workspace, booking page, _(client) portal, and website. You can also link a custom email domain to make sure that all notifications to _(experts) and _(clients) come from your business email address. To get started, enable the {customDomainLink} and {customEmailLink} features in the Modular system.',
  'customDomains.customDomainFeature': 'Custom domain',
  'customDomains.customEmailDomainFeature': 'Custom email domain',
  'customDomains.setupInstructions':
    'Enable custom domain to connect your own website address to your ExpertBox workspace, website, booking widget and _(client) portal. For detailed instructions on buying, connecting, and troubleshooting domains, please refer to this <span><link>Help Center article</link></span>.',
  'customDomains.customDomain.title': 'Custom workspace domain',
  'customDomains.connectDomain': 'Connect domain',
  'customDomains.customDomain.placeholder': 'example.com',
  'customDomains.customDomain.label': 'Enter your domain',
  'customDomains.customDomain.connectionStatus': 'Connection status:',
  'customDomains.customDomain.status.refreshStatus': 'Refresh status',
  'customDomains.customDomain.status.stopConnection': 'Stop connection process',
  'customDomains.customDomain.status.disconnectDomain': 'Disconnect domain',
  'customDomains.customDomain.status.pending.title': 'Pending',
  'customDomains.customDomain.status.connected.title': 'Connected',
  'customDomains.customDomain.status.failed.title': 'Failed',
  'customDomains.customDomain.status.disconnected.title': 'Disconnected',
  'customDomains.customDomain.status.pending.description':
    'Your Custom workspace domain is in the process of being connected. This may take up to 72 hours. You’ll receive an email when there’s an update.',
  'customDomains.customDomain.status.connected.description':
    'Your Custom workspace domain has been successfully connected. Your booking pages, website, and _(client) portal can now be accessed using this domain.',
  'customDomains.customDomain.status.failed.description':
    'Your Custom workspace domain failed to connect, indicating that something was entered incorrectly in the DNS settings through your domain provider.',
  'customDomains.customDomain.status.failed.cnameWarning':
    'A CNAME record could not be found. Follow the instructions again to add this record.',
  'customDomains.customDomain.status.disconnected.description':
    'This status means something was changed such that the URL no longer works. Next steps: Log in to your domain manager account and double check that the DNS settings are correct and that you still own the rights to the Custom workspace domain.',
  'customDomains.domain.validation.invalidFormat': 'Invalid domain',
  'customDomains.domain.validation.minLength': 'Can’t be less than 4 characters',
  'customDomains.dnsRecords.title': 'DNS Authentication',
  'customDomains.dnsRecords.description':
    "To begin the verification process, log into your domain provider and access the domain DNS management section. You'll need to add the DNS records provided in your ExpertBox dashboard. Please note, the verification process can take up to 72 hours.",

  'customDomains.recordsTable.type': 'Type',
  'customDomains.recordsTable.name': 'Name',
  'customDomains.recordsTable.value': 'Value',
  'customDomains.recordsTable.types.a_record': 'A',
  'customDomains.recordsTable.types.cname': 'CNAME',
}

export default customDomains
