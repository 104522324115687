import { includes } from 'ramda'

import { FREE_DOMAINS } from 'lib/constants/customEmailDomains'

// eslint-disable-next-line import/prefer-default-export
export function isBusinessEmail() {
  return this.test('senderEmail', { id: 'yup.string.businessEmail' }, value => {
    const domain = value?.split('@')?.pop()

    return !includes(domain, FREE_DOMAINS)
  })
}
