import {
  subscriptionsAnnualDowngradeRoute,
  subscriptionsAnnualUpgradeRoute,
  subscriptionsFreeUpgradeRoute,
  subscriptionsMonthlyDowngradeRoute,
  subscriptionsMonthlyUpgradeRoute,
  subscriptionsTrialUpgradeRoute,
} from 'lib/apiRoutes'
import { ceil, values } from 'lodash'
import { DateTime } from 'luxon'
import { gt, negate, prop, propEq } from 'ramda'
import { FormattedMessage, FormattedNumber } from 'react-intl'

import {
  CYCLE_TYPES,
  PLAN_TYPES,
  STORAGE_ABOUT_TO_EXCEED_PERCENTAGE,
  STORAGE_EXCEEDED_PERCENTAGE,
  SUBSCRIPTION_PLANS_LAST_FEATURES,
  SUBSCRIPTION_PLANS_SECTIONS,
  TRANSACTION_STATUSES,
  TRIAL_EXPIRATION_DAYS,
} from 'lib/constants/billing'
import { SECONDS_PER_HOUR } from 'lib/constants/timeUnits'
import percentage from 'utils/percentage'
import Checked from 'views/shared/icons/Checked'
import Cross from 'views/shared/icons/Cross'

export const storageSize = bytes => {
  const kilobytes = bytes / 1024
  const megabytes = bytes / 1024 / 1024
  const gigabytes = bytes / 1024 / 1024 / 1024

  if (gigabytes >= 1) {
    return `${ceil(gigabytes, 2)} GB`
  }
  if (megabytes >= 1) {
    return `${ceil(megabytes, 2)} MB`
  }
  if (kilobytes >= 1) {
    return `${ceil(kilobytes, 2)} KB`
  }

  return `${ceil(bytes, 2)} B`
}

export const hoursFromSeconds = seconds => ceil(seconds / SECONDS_PER_HOUR, 2)

export const progressBarWidth = (used, total, isOverflowed) => {
  const width = percentage(used, total)

  if (width > 100) {
    return isOverflowed ? 100 : percentage(100, width)
  }

  return width
}

export const isExpired = (used, total) => percentage(used, total) >= 80
export const isProgressBarFilled = (used, total) => used > total
export const isIncomplete = (used, total) => percentage(used, total) > 100

export const splitPrice = price => {
  const splittedPrice = price.split('.')

  return { ceil: splittedPrice[0], fraction: splittedPrice[1] || '00' }
}

export const isPaid = status => status === TRANSACTION_STATUSES.paid
export const isPending = status => status === TRANSACTION_STATUSES.pending
export const isFailed = status => status === TRANSACTION_STATUSES.failed

export const isFreePlan = subscriptionPlan => propEq(PLAN_TYPES.free, 'planType', subscriptionPlan)
export const isPremiumPlan = subscriptionPlan => propEq(PLAN_TYPES.premium, 'planType', subscriptionPlan)
export const isPremiumPlusPlan = subscriptionPlan => propEq(PLAN_TYPES.premiumPlus, 'planType', subscriptionPlan)

export const dateDifferenceInDays = (date, otherDate) =>
  ceil(DateTime.fromISO(date).diff(DateTime.fromISO(otherDate), 'days').days)
export const getRemainingDays = paymentDate => dateDifferenceInDays(paymentDate, DateTime.local().toISO())

export const isFreeOrTrial = subscriptionPlan => isFreePlan(subscriptionPlan) || subscriptionPlan?.trial

export const hasTeamSeatsDowngrade = subscriptionPlan => gt(prop('teamSeatsDowngradedQuantity', subscriptionPlan), 0)

export const getDiscount = subscriptionPlan => {
  const { subscriptionPlanPrice, annualPrice } = subscriptionPlan || {}

  if (!subscriptionPlanPrice || !annualPrice) {
    return 0
  }
  return ceil((1 - annualPrice / subscriptionPlanPrice) * 100)
}

export const isAnnual = subscriptionPlan => subscriptionPlan?.subscriptionCycleType === CYCLE_TYPES.annual
export const trialProgressWidth = subscriptionPlan => {
  const remainingDays = getRemainingDays(prop('paymentDate', subscriptionPlan))
  const periodDuration = prop('billingPeriodDuration', subscriptionPlan)

  return progressBarWidth(remainingDays, periodDuration)
}

export const isTrialAboutToExpired = subscriptionPlan =>
  getRemainingDays(prop('paymentDate', subscriptionPlan)) <= TRIAL_EXPIRATION_DAYS

export const isStorageExceeded = storagePlan => storagePlan.usedStorage >= storagePlan.totalStorage
export const isStorageAboutToExceed = storagePlan => {
  const percent = percentage(storagePlan.usedStorage, storagePlan.totalStorage)
  return percent < STORAGE_EXCEEDED_PERCENTAGE && percent >= STORAGE_ABOUT_TO_EXCEED_PERCENTAGE
}
export const isTeamSeatsExceeded = subscriptionPlan =>
  subscriptionPlan.usedTeamSeatsCapacity >= subscriptionPlan.teamSeatsCapacity

export const isSubscriptionPlanFeatureSection = featureKey => values(SUBSCRIPTION_PLANS_SECTIONS).includes(featureKey)
export const isLastFeatureInSection = featureKey => values(SUBSCRIPTION_PLANS_LAST_FEATURES).includes(featureKey)
export const isSamePlan = (currentPlan, newPlan) => currentPlan?.id === newPlan.id
export const isDowngradablePlan = (currentPlan, newPlan) =>
  (!isSamePlan(currentPlan, newPlan) && isPremiumPlusPlan(currentPlan)) ||
  (isPremiumPlan(currentPlan) && isFreePlan(newPlan))

export const formatSeatsValue = value => (value > 0 ? `+${value}` : value)

export const getRowClassName = ({ key }) => {
  const isSection = isSubscriptionPlanFeatureSection(key)
  const isLastFeature = isLastFeatureInSection(key)
  if (isSection) return 'freemium-table__group-headline'
  if (isLastFeature) return 'freemium-table__group-end'

  return null
}

export const getSubscriptionPlanCycleOptions = subscriptionPlan => [
  {
    label: (
      <div className="d-flex align-items-center justify-content-center">
        <span className="freemium-table-plans__segmented-text line-height-20 in-green-700">
          <FormattedMessage id="subscriptionPlans.table.annualy" />
        </span>
        <span className="main-badge main-badge--draft-freemium main-badge--success main-badge--no-dots pl-8 font-400 ml-8">
          <FormattedNumber
            // eslint-disable-next-line react/style-prop-object
            style="percent"
            value={negate(getDiscount(subscriptionPlan) / 100)}
          />
        </span>
      </div>
    ),
    value: CYCLE_TYPES.annual,
  },
  {
    label: (
      <div className="d-flex align-items-center justify-content-center">
        <span className="freemium-table-plans__segmented-text line-height-20 in-blue-gray-300">
          <FormattedMessage id="subscriptionPlans.table.monthly" />
        </span>
      </div>
    ),
    className: 'freemium-table-plans__segmented-item-wide',
    value: CYCLE_TYPES.monthly,
  },
]

export const getSubscriptionPlanFeatureDescription = ({ includedStorage }) => ({
  services: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <FormattedMessage id="subscriptionPlans.table.unlimited" />,
  },
  appointments: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <FormattedMessage id="subscriptionPlans.table.unlimited" />,
  },
  recurringAppointments: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  availability: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  multipleLocations: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  calendarIntegration: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  sms: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <FormattedMessage id="subscriptionPlans.table.payAsYouGo" />,
  },
  email: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  bookingPage: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <FormattedMessage id="subscriptionPlans.table.unlimited" />,
  },
  bookingWidgets: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  website: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  waitlist: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  reviews: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  acceptVideoCalls: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <FormattedMessage id="subscriptionPlans.table.unlimited" />,
  },
  groupVideoCalls: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <FormattedMessage id="subscriptionPlans.table.payAsYouGo" />,
  },
  videoCallRecording: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <FormattedMessage id="subscriptionPlans.table.payAsYouGo" />,
  },
  teamMembers: {
    inactive: '1',
    active: <FormattedMessage id="subscriptionPlans.table.unlimited" />,
  },
  team: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  storage: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: storageSize(includedStorage),
  },
  crm: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  clientPortal: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  chat: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  forms: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  textDocuments: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  documentTemplates: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  signature: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  voiceMessage: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  acceptPayments: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  customPayments: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  stripePos: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  financialDocuments: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  refunds: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  clientContacts: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  businessFiles: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  customDomains: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
  customNaming: {
    inactive: <Cross size={10} className="in-gray-400" />,
    active: <Checked className="in-green-600" />,
  },
})

export const getUpdateSubscriptionsUrl = ({ isTrial, isUpgrade, isCurrentPlanFree, subscriptionCycleType }) => {
  if (isTrial) {
    return subscriptionsTrialUpgradeRoute
  }

  if (isCurrentPlanFree) {
    return subscriptionsFreeUpgradeRoute
  }

  if (subscriptionCycleType === CYCLE_TYPES.monthly) {
    return isUpgrade ? subscriptionsMonthlyUpgradeRoute : subscriptionsMonthlyDowngradeRoute
  }
  return isUpgrade ? subscriptionsAnnualUpgradeRoute : subscriptionsAnnualDowngradeRoute
}

export const getCancelBookingOptions = isRefundsEnabled => [
  {
    label: {
      id: `billing.downgradeToFree.${isRefundsEnabled ? 'cancelAndRefundAllAppointments' : 'cancelAllAppointments'}`,
    },
    value: 'true',
  },
  { label: { id: 'billing.downgradeToFree.reassignAppointments' }, value: 'false' },
]
