import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { currentWorkspaceIdSelector } from 'state/concepts/session/selectors'
import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { getCustomDomainEndpoint } from '../endpoints'
import { GET_CUSTOM_DOMAIN } from '../types'

const getCustomDomain = createLogic({
  type: GET_CUSTOM_DOMAIN,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const state = getState()
    const { url, endpoint } = getCustomDomainEndpoint
    const workspaceId = currentWorkspaceIdSelector(state)

    dispatch(dataApiRequest({ endpoint }))

    const params = { include: 'dns-records' }

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      const workspaceResponse = updateDataHelper(state.data, 'workspace', workspaceId, {
        relationships: {
          customDomain: {
            data: {
              id: data.data.id,
              type: 'customDomain',
            },
          },
        },
      })
      const fullResponse = { ...response, ...workspaceResponse }

      dispatch(dataApiSuccess({ response: fullResponse, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, excludeErrorArray: [404] })
    }
    done()
  },
})

export default getCustomDomain
