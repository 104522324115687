const customEmailDomains = {
  'customEmailDomains.title': 'Custom email domain',
  'customEmailDomains.setupInstructions':
    'Enable custom email domain to send all email notifications to _(experts) and _(clients) from your business email address. For detailed instructions on setting up and troubleshooting email domains, please refer to this <span><link>Help Center article.</link></span>',
  'customEmailDomains.senderDetails.title': 'Sender details',
  'customEmailDomains.senderDetails.description':
    "Input the name and email address of the sender you would like to use for outgoing emails. You will be required to access your domain's DNS manager to verify the domain by adding a few CNAME records.",
  'customEmailDomains.senderName.placeholder': 'e.g., Acme Consulting',
  'customEmailDomains.senderName.label': 'Sender name',
  'customEmailDomains.senderEmail.label': 'Sender email',
  'customEmailDomains.senderEmail.placeholder': 'e.g., noreply@acme.com',
  'customEmailDomains.submitText': 'Submit for verification',
  'customEmailDomains.connectionStatus': 'Connection status:',
  'customEmailDomains.status.pending': 'Pending',
  'customEmailDomains.status.pending.description':
    'The domain verification is in progress. Please wait while the DNS records are being verified.',
  'customEmailDomains.status.failed': 'Failed',
  'customEmailDomains.status.failed.description':
    'The domain verification process exceeded 72 hours and failed to verify the DNS records. We are still attempting to verify it, but please check your domain manager settings to ensure everything is correctly configured.',
  'customEmailDomains.status.disconnected': 'Disconnected',
  'customEmailDomains.status.disconnected.description':
    'Something changed in the DNS settings, causing the custom email domain to get disconnected. Please review and update your DNS settings in your domain manager.',
  'customEmailDomains.status.connected': 'Connected',
  'customEmailDomains.status.connected.description':
    'The domain is verified. All emails come from <span>{senderName} ({senderEmail})</span>.',
  'customEmailDomains.disconnect': 'Disconnect custom email domain',
  'customEmailDomains.stopConnection': 'Stop connection process',
  'customEmailDomains.refreshStatus': 'Refresh status',
  'customEmailDomains.dnsRecords.title': 'DNS Authentication',
  'customEmailDomains.dnsRecords.description':
    "To begin the verification process, log into your DNS provider and access the DNS management section. You'll need to add the DNS records provided in your ExpertBox dashboard. Please note, the verification process can take up to 72 hours.",
  'customEmailDomains.dnsRecords.type': 'Type',
  'customEmailDomains.dnsRecords.type.cname': 'CNAME',
  'customEmailDomains.dnsRecords.name': 'Name',
  'customEmailDomains.dnsRecords.value': 'Value',
  'customEmailDomains.disconnectModal.title': 'Disconnect custom email domain?',
  'customEmailDomains.disconnectModal.description':
    'From now on, all emails will be sent using the default email settings.',
  'customEmailDomains.disconnectModal.submitText': 'Disconnect domain',
  'customEmailDomains.stopConnectionModal.title': 'Stop connection process?',
  'customEmailDomains.stopConnectionModal.description':
    'Your custom email domain will not be connected and the workspace will continue using the default email settings.',
  'customEmailDomains.stopConnectionModal.submitText': 'Stop connection process',
}

export default customEmailDomains
