const customDomainFeature = {
  'customDomainFeature.title': 'Custom workspace domain',
  'customDomainFeature.titleDescription':
    'Enhance your professional presence by connecting a custom domain to your ExpertBox platform.',
  'customDomainFeature.inSettings': '<span>in</span> Workspace settings',
  'customDomainFeature.manageSettings': 'Manage settings',
  'customDomainFeature.connectDomain':
    'Connect a custom domain to your ExpertBox platform to strengthen your brand identity and ensure a seamless experience for your _(clients).',
  'customDomainFeature.block1': 'Enhance professional identity:',
  'customDomainFeature.block1.point1':
    'Use a custom domain to make your business appear more professional and credible.',
  'customDomainFeature.block1.point2':
    'Reflect your brand consistently across all _(client) interactions by using your personalized domain.',
  'customDomainFeature.block2': 'Seamless _(client) experience:',
  'customDomainFeature.block2.point1':
    'Provide _(clients) with a familiar and branded web address, making it easier for them to remember and access your _(services).',
  'customDomainFeature.block2.point2':
    'Reduce confusion and enhance trust with a custom domain that aligns with your brand.',
  'customDomainFeature.toLearnMore':
    'To learn more, read our help center article on <link>setting up Custom Domain</link>.',
  'customDomainFeature.disableModalTitle': 'Disable custom domain feature?',
  'customDomainFeature.disableModalDescription':
    'If you have a connected custom domain, it will be disconnected when this feature is disabled, and the default ExpertBox domain will be used instead.',
}

export default customDomainFeature
