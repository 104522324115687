import { createSelector } from '@reduxjs/toolkit'
import { ascend, compose, equals, not, path, pipe, prop, propEq, propOr, sortWith } from 'ramda'
import build from 'redux-object'

import { BILLING_PERIOD_STATUSES, PLAN_TYPES_WEIGHT } from 'lib/constants/billing'
import { CLIENT_NAMESPACE_PREFIX, EMPLOYEE_NAMESPACE_PREFIX } from 'lib/constants/broadcastChannel'
import { getClientOwnProfileEndpoint } from 'state/concepts/client/endpoints'
import { getAppUthUriEndpoint, getEmployeeOwnProfileEndpoint } from 'state/concepts/employee/endpoints'
import { workspaceSelector as subdomainWorkspaceSelector } from 'state/concepts/subdomain/selectors'
import { dataSelector, endpointMetaSelector, loadingSelector } from 'state/data/selectors'
import { isFreePlan } from 'utils/billing'
import isPresent from 'utils/isPresent'
import profileInitials from 'utils/profileInitials'
import { employeeLoginEndpoint } from './endpoints'

export const currentClientIdSelector = path(['session', 'currentClientId'])

export const currentEmployeeIdSelector = path(['session', 'currentEmployeeId'])

export const currentGuestIdSelector = path(['session', 'currentGuestId'])

export const currentEmployeeSelector = createSelector([currentEmployeeIdSelector, dataSelector], (id, data) =>
  data.userProfile?.[id] ? build(data, 'userProfile', id, { includeType: true }) : null,
)

export const appAuthUriSelector = state => prop('uri', endpointMetaSelector(state, getAppUthUriEndpoint.endpoint))

export const appAuthUriLoadingSelector = state => loadingSelector(state, getAppUthUriEndpoint.endpoint)

export const employeeLoginMetaSelector = state => endpointMetaSelector(state, employeeLoginEndpoint.endpoint)

export const isNeedTwoFactorSelector = pipe(employeeLoginMetaSelector, prop('needTwoFactor'))

export const currentEmployeeServicesSelector = createSelector(
  currentEmployeeSelector,
  compose(
    sortWith([ascend(path(['service', 'status'])), ascend(path(['service', 'name']))]),
    propOr([], 'userProfilesServices'),
  ),
)

export const currentCalendarViewSettingSelector = pipe(currentEmployeeSelector, prop('calendarViewSetting'))

export const currentClientSelector = createSelector([currentClientIdSelector, dataSelector], (id, data) =>
  data.clientProfile?.[id] ? build(data, 'clientProfile', id, { includeType: true }) : null,
)

export const currentClientPendingAppointmentsCountSelector = createSelector(
  currentClientSelector,
  prop('requestedAppointmentsCount'),
)
export const currentClientPendingFormsCountSelector = pipe(currentClientSelector, prop('pendingFormsCount'))
export const currentClientPendingAgreementsCountSelector = pipe(currentClientSelector, prop('pendingAgreementsCount'))
export const currentClientRequireDocumentsCountSelector = pipe(currentClientSelector, prop('requireDocumentsCount'))
export const currentClientSharedDocumentsCountSelector = pipe(currentClientSelector, prop('sharedDocumentsCount'))
export const currentClientPendingFilesCountSelector = pipe(currentClientSelector, prop('pendingFilesCount'))
export const currentClientActiveTransactionsCountSelector = pipe(currentClientSelector, prop('activeTransactionsCount'))

export const currentGuestSelector = createSelector([currentGuestIdSelector, dataSelector], (id, data) =>
  data?.clientProfile?.[id] ? build(data, 'clientProfile', id, { includeType: true }) : null,
)

export const currentUserProfileSelector = createSelector(
  [currentClientSelector, currentGuestSelector, currentEmployeeSelector],
  (clientProfile, guestProfile, userProfile) => clientProfile || guestProfile || userProfile,
)

export const currentUserTimezoneSelector = pipe(currentUserProfileSelector, prop('timezone'))

export const currentUserTimezoneOffsetSelector = pipe(currentUserProfileSelector, prop('timezoneOffset'))

export const currentUserInitialsSelector = pipe(currentUserProfileSelector, profileInitials)

export const currentUserUnviewedWaitlistCountSelector = pipe(
  currentUserProfileSelector,
  prop('unviewedWaitlistRequestsCount'),
)

export const isEmployeeLoggedInSelector = pipe(currentEmployeeIdSelector, isPresent)
export const isClientLoggedInSelector = pipe(currentClientIdSelector, isPresent)
export const isGuestLoggedInSelector = pipe(currentGuestIdSelector, isPresent)

export const currentEmployeeRoleSelector = pipe(currentEmployeeSelector, prop('roleName'))

export const currentWorkspaceSelector = pipe(currentUserProfileSelector, prop('workspace'))

export const currentWorkspaceTwoFactorSettingsSelector = pipe(
  currentWorkspaceSelector,
  prop('workspaceTwoFactorSetting'),
)

export const currentWorkspaceLocationsCountSelector = pipe(
  currentEmployeeSelector,
  path(['workspace', 'locationsCount']),
)

export const currentWorkspaceIdSelector = pipe(subdomainWorkspaceSelector, prop('id'))

export const currentWorkspaceCodeSelector = pipe(subdomainWorkspaceSelector, prop('uniqCode'))

export const currentEmployeeTutorialSelector = pipe(currentEmployeeSelector, prop('userTutorial'))

export const currentWorkspacePaymentEnabledSelector = pipe(
  currentWorkspaceSelector,
  workspace =>
    isPresent(path(['paymentAccount', 'stripeAccount'], workspace)) ||
    isPresent(path(['paymentAccount', 'paypalAccount'], workspace)),
)

export const currentWorkspaceManualPaymentEnabledSelector = pipe(
  currentWorkspaceSelector,
  compose(isPresent, path(['paymentAccount', 'manualPayment'])),
)

export const currentWorkspaceStripePaymentSelector = pipe(
  currentWorkspaceSelector,
  path(['paymentAccount', 'stripeAccount']),
)

export const currentWorkspaceManualPaymentSelector = pipe(
  currentWorkspaceSelector,
  path(['paymentAccount', 'manualPayment']),
)

export const currentWorkspacePaymentMethodConnectedSelector = createSelector(
  [currentWorkspacePaymentEnabledSelector, currentWorkspaceManualPaymentEnabledSelector],
  (paymentEnabled, manualPaymentEnabled) => paymentEnabled || manualPaymentEnabled,
)

export const currentWorkspaceRecordingEnabledSelector = pipe(currentWorkspaceSelector, prop('recordingEnabled'))

export const currentWorkspaceInvoicingEnabledSelector = pipe(currentWorkspaceSelector, prop('invoicingEnabled'))

export const currentEmployeeLoadingSelector = state => loadingSelector(state, getEmployeeOwnProfileEndpoint.endpoint)

export const currentClientLoadingSelector = state => loadingSelector(state, getClientOwnProfileEndpoint.endpoint)

export const currentOnboardingContentSelector = createSelector(
  [currentUserProfileSelector, isEmployeeLoggedInSelector],
  (profile, isEmployeeLoggedIn) =>
    propOr({}, isEmployeeLoggedIn ? 'userOnboardingContent' : 'clientOnboardingContent', profile),
)

export const currentBillingPeriodSelector = pipe(currentWorkspaceSelector, prop('currentBillingPeriod'))

export const currentSubscriptionStatusSelector = pipe(currentBillingPeriodSelector, prop('status'))

export const currentSubscriptionEndingAtSelector = pipe(currentBillingPeriodSelector, prop('endingAt'))

export const isActiveSubscriptionSelector = pipe(
  currentSubscriptionStatusSelector,
  equals(BILLING_PERIOD_STATUSES.open),
)

export const entityWorkspaceUniqCodeSelector = createSelector(
  [(_, id) => id, (_, __, type) => type, dataSelector],
  (id, type, data) => path(['workspace', 'uniqCode'], build(data, type, id)),
)

export const currentClientLogoutIntervalSelector = pipe(currentClientSelector, prop('logoutInterval'))

export const currentEmployeeLogoutIntervalSelector = pipe(currentEmployeeSelector, prop('logoutInterval'))

export const broadcastChannelNamespaceSelector = createSelector(
  [currentClientIdSelector, currentEmployeeIdSelector],
  (currentClientId, currentEmployeeId) => {
    const prefix = currentClientId ? CLIENT_NAMESPACE_PREFIX : EMPLOYEE_NAMESPACE_PREFIX

    return `${prefix}-${currentClientId || currentEmployeeId}`
  },
)

export const hasLocationsSelector = pipe(currentWorkspaceSelector, compose(not, propEq(0, 'locationsCount')))

export const isWorkspaceOwnerSelector = pipe(currentEmployeeRoleSelector, equals('workspace_owner'))
export const isAdminSelector = pipe(currentEmployeeRoleSelector, equals('administrator'))

export const currentSubscriptionPlanSelector = pipe(currentWorkspaceSelector, prop('currentSubscriptionPlan'))
export const isPermissionBySubscriptionPlanTypeSelector = createSelector(
  [currentSubscriptionPlanSelector, (_state, planType) => planType],
  (subscriptionPlan, planType) => PLAN_TYPES_WEIGHT[prop('planType', subscriptionPlan)] < PLAN_TYPES_WEIGHT[planType],
)
export const isFreePlanSelector = pipe(currentSubscriptionPlanSelector, isFreePlan)

export const userProfileTwoFactorSettingSelector = pipe(currentEmployeeSelector, prop('userProfileTwoFactorSetting'))

export const currentContactSelector = pipe(currentUserProfileSelector, prop('currentContact'))

export const currentContactProfileSelector = pipe(currentContactSelector, prop('contactClientProfile'))

export const currentContactPermissionsSelector = pipe(currentContactSelector, prop('clientRelationshipType'))
export const isCurrentContactResponsibleForBillingSelector = pipe(currentContactSelector, prop('responsibleForBilling'))

export const currentProfileSelector = createSelector(
  [currentUserProfileSelector, currentContactProfileSelector],
  (currentUser, currentContact) => currentContact || currentUser,
)

export const currentChatUserIdSelector = pipe(currentProfileSelector, prop('chatUserId'))
export const currentSignatureDisclosureAcceptedAtSelector = pipe(
  currentProfileSelector,
  prop('signatureDisclosureAcceptedAt'),
)

export const currentWorkspaceCustomDomainSelector = pipe(subdomainWorkspaceSelector, prop('customDomain'))

export const currentWorkspaceCustomEmailDomainSelector = pipe(currentWorkspaceSelector, prop('customEmailDomain'))
