import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const UpgradeStars = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M1010.859 555.522l-232.833-96.984-96.982-232.832c-6.657-15.914-32.769-15.914-39.426 0l-96.976 232.832-232.835 96.984c-7.978 3.323-13.141 11.089-13.141 19.708s5.163 16.385 13.141 19.714l232.835 96.982 96.976 232.833c3.329 7.978 11.094 13.14 19.714 13.14s16.385-5.162 19.714-13.14l96.982-232.833 232.833-96.982c7.978-3.329 13.14-11.094 13.14-19.714s-5.162-16.385-13.14-19.708zM454.74 256.298l-149.845-49.963-49.963-149.845c-5.846-17.408-34.688-17.408-40.491 0l-50.005 149.845-149.845 49.963c-8.704 2.943-14.592 11.093-14.592 20.266s5.889 17.323 14.592 20.267l149.888 49.963 49.963 149.888c2.901 8.661 11.050 14.55 20.224 14.55s17.323-5.889 20.267-14.592l49.963-149.888 149.888-49.963c8.661-2.901 14.549-11.050 14.549-20.224s-5.887-17.323-14.592-20.266zM369.406 810.964l-85.888-28.631-28.629-85.888c-5.846-17.409-34.688-17.409-40.491 0l-28.63 85.888-85.888 28.631c-8.661 2.946-14.549 11.094-14.549 20.264 0 9.177 5.887 17.324 14.592 20.271l85.888 28.626 28.63 85.888c2.901 8.665 11.050 14.552 20.224 14.552s17.323-5.887 20.267-14.591l28.629-85.888 85.888-28.631c8.661-2.901 14.55-11.049 14.55-20.226 0-9.171-5.889-17.319-14.592-20.264z" />
  </svg>
)

UpgradeStars.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

UpgradeStars.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default UpgradeStars
