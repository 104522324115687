export const CUSTOM_DOMAIN_STATUSES = {
  pending: 'pending',
  connected: 'connected',
  failed: 'failed',
  disconnected: 'disconnected',
}

export const MIN_DOMAIN_SIZE = 4

export const MAX_DOMAIN_SIZE = 70

export const DOMAIN_FORMAT = /^(?=.{4,70}$)(?:(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$/

export const EXCLUDE_WWW_SUBDOMAIN_REGEX = /^w+\./i

export const DNS_RECORD_STATUSES = {
  verified: 'verified',
  pending: 'pending',
}
