import { prop, propOr } from 'ramda'

import { PORTAL_HOST } from 'lib/constants'
import { clientLoginRoute } from 'lib/routes'
import { CUSTOM_DOMAIN_STATUSES } from 'lib/constants/customDomains'

export const getPortalUrl = workspace => {
  const customDomain = prop('customDomain', workspace)
  const url = propOr('', 'url', workspace)

  return customDomain?.status === CUSTOM_DOMAIN_STATUSES.connected
    ? `https://${customDomain.domain}`
    : PORTAL_HOST.replace('://', `://${url}.`)
}

export const getPortalLoginLink = workspace => `${getPortalUrl(workspace)}${clientLoginRoute}`
