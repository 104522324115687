import endpoint from 'utils/endpoint'
import { customEmailDomainRoute } from 'lib/apiRoutes'
import * as types from './types'

export const connectCustomEmailDomainEndpoint = endpoint(
  types.CONNECT_CUSTOM_EMAIL_DOMAIN,
  'POST',
  customEmailDomainRoute,
)

export const disconnectCustomEmailDomainEndpoint = endpoint(
  types.DISCONNECT_CUSTOM_EMAIL_DOMAIN,
  'DELETE',
  customEmailDomainRoute,
)
