const workspaceFeatures = {
  'features.title': 'Features',
  'features.premiumFeature': 'Premium feature',
  'features.upgradePlan': 'Upgrade plan',
  'features.availability.title': 'Availability',
  'features.availability.description': 'Set your working hours to let _(clients) know your schedule.',
  'features.booking.title': '_(Appointments)',
  'features.booking.description': 'Schedule _(appointments) with your _(clients) and update or cancel them as needed.',
  'features.recurringAppointments.title': 'Recurring _(appointments)',
  'features.recurringAppointments.description': 'Set a recurring schedule for your _(clients)’ _(appointments).',
  'features.clientPortal.title': '_(Client) portal',
  'features.clientPortal.description':
    'Let _(clients) manage all their activity: _(appointments), communication, payments.',
  'features.clientContacts.title': '_(Client) contacts',
  'features.clientContacts.description':
    'Streamline _(client) relationships by adding and managing their related contacts.',
  'features.services.title': '_(Services)',
  'features.services.description': 'Create and edit _(services), booking rules, and more.',
  'features.serviceCategories.title': '_(Service) categories',
  'features.serviceCategories.description':
    'Group _(services) into categories so _(clients) can easily find what they need.',
  'features.tasks.title': 'Tasks',
  'features.tasks.description': 'Add tasks to manage your work and keep track of the deadlines.',
  'features.groupVideoCalls.title': 'ExpertBox group video calls',
  'features.groupVideoCalls.description': 'Have high-quality video meetings with _(clients) inside your workspace.',
  'features.videoCallsRecording.title': 'ExpertBox video calls recording',
  'features.videoCallsRecording.description': 'Record your video calls to review them and share them with _(clients).',
  'features.acceptVideoCalls.title': 'Accept video calls',
  'features.acceptVideoCalls.description': 'Have high-quality video meetings with _(clients) inside your workspace.',
  'features.bookingPage.title': 'Booking page',
  'features.bookingPage.description': 'Get a booking link to share with _(clients) and get booked online.',
  'features.bookingWidgets.title': 'Booking widgets',
  'features.bookingWidgets.description': 'Add a booking page to your website as a widget button or an embedded panel.',
  'features.website.title': 'Website',
  'features.website.description': 'Set up a professional-looking booking website.',
  'features.locations.title': 'Offline locations',
  'features.locations.description': 'Add your physical business locations to offer _(services) offline.',
  'features.team.title': 'Team',
  'features.team.description': 'Invite your team members and organize their workloads.',
  'features.clientList.title': '_(Client) list',
  'features.clientList.description': 'Keep your _(client) information organized and access it anytime.',
  'features.crm.title': 'CRM',
  'features.crm.description':
    'Manage all _(client)-related activity and build strong relationships with your _(clients).',
  'features.chat.title': 'Chat',
  'features.financialDocuments.title': 'Financial documents',
  'features.financialDocuments.description':
    'Automate invoice and receipt generation, then download invoices and receipts.',
  'features.chat.description': 'Stay in touch with your team and _(clients).',
  'features.acceptPayments.title': 'Accept payments',
  'features.acceptPayments.description': 'Accept payments through ExpertBox using online or manual payment methods.',
  'features.customPayments.title': 'Custom payments',
  'features.customPayments.description': 'Send payment requests for any products or _(services) you provide.',
  'features.refunds.title': 'Refunds',
  'features.refunds.description': 'Set up a clear refund policy and automate your refunds.',
  'features.emailReminders.title': 'Email reminders & notifications',
  'features.emailReminders.description':
    'Stay informed about all the important things that are happening in your workspace.',
  'features.smsReminders.title': 'SMS reminders & notifications',
  'features.smsReminders.description': 'Reach your _(clients) in a fast and convenient way, wherever they are.',
  'features.customNaming.title': 'Custom naming',
  'features.customNaming.description':
    'Customize naming across your workspace to speak the same language with your _(clients).',
  'features.customDomains.title': 'Custom workspace domain',
  'features.customDomains.description': 'Connect your domain to the workspace to boost your professional presence.',
  'features.serviceAgreements.title': '_(Service agreements)',
  'features.serviceAgreements.description': 'Ask _(clients) to sign legal documents before providing _(services).',
  'features.signature.title': 'E-signature',
  'features.signature.description': 'Streamline form and document signing and ensure legally binding signatures.',
  'features.documents.title': 'Documents',
  'features.documents.description':
    'Create and collaborate on documents with team members and _(clients) in real-time.',
  'features.documentTemplates.title': 'Document templates',
  'features.documentTemplates.description':
    'Create templates like agreements, training programs, progress reports, and more.',
  'features.forms.title': 'Forms',
  'features.forms.description':
    'Collect information from _(clients) before _(appointments) to provide better _(service).',
  'features.reviews.title': 'Reviews',
  'features.reviews.description': 'Share feedback about your _(services) and increase _(clients)’ trust.',
  'features.calendarIntegrations.title': 'Calendar integrations',
  'features.calendarIntegrations.description':
    'Sync calendars to avoid double-bookings and manage schedule across platforms.',
  'features.voiceMessages.title': 'Voice messages',
  'features.voiceMessages.description': 'Connect with team and _(clients) in a quick and easy way.',
  'features.stripePos.title': 'Stripe Point of Sale',
  'features.stripePos.description': 'Accept in-person payments using Stripe card reader.',
  'features.waitlist.title': 'Waitlist',
  'features.waitlist.description':
    'Quickly fill openings and enhance _(client) satisfaction with an easy-to-use waitlist system.',
  'features.status.enabled': 'Enabled',
  'features.status.disabled': 'Disabled',
  'features.manageSettings': 'Manage settings',
  'features.manageFeatures': 'Go to _(clients)',
  'features.featureSetup': 'Getting started',
  'features.searchBy': 'Search by name',
  'features.categories': 'Categories:',
  'features.category.booking': 'Booking',
  'features.category.payments': 'Payments',
  'features.category.teamManagement': 'Team management',
  'features.category.services': '_(Services)',
  'features.category.clients': '_(Clients)',
  'features.category.communication': 'Communication',
  'features.category.website': 'Website',
  'features.category.locations': 'Locations',
  'features.category.workspaceSettings': 'Workspace settings',
  'features.category.remindersAndNotifications': 'Reminders & notifications',
  'features.category.formsAndDocuments': 'Forms & documents',
  'features.module.scheduling': 'Scheduling',
  'features.module.workspaceSettings': 'Workspace settings',
  'features.module.booking': 'Booking',
  'features.module.clientPortal': '_(Client) portal',
  'features.module.services': '_(Services)',
  'features.module.taskManagement': 'Tasks management',
  'features.module.videoCalls': 'Video calls',
  'features.module.teamManagement': 'Team management',
  'features.module.website': 'Website',
  'features.module.locations': 'Locations',
  'features.module.crm': 'CRM',
  'features.module.messenger': 'Messenger',
  'features.module.payments': 'Payments',
  'features.module.remindersAndNotifications': 'Reminders & notifications',
  'features.module.formsAndDocuments': 'Forms & documents',
  'features.module.filesStorage': 'Files storage',
  'features.module.reviews': 'Reviews',
  'features.module.voiceMessages': 'Voice Messages',
  'features.adjustSearch': 'Try adjusting your search or filters',
  'features.enableFeature': 'Enable feature',
  'features.disableFeature': 'Disable feature',
  'features.availableOnlyForAdvanced': 'Available only for advanced plans',
  'features.cantManage':
    'You don’t have permission to manage this feature. Please ask your workspace owner for assistance.',
  'features.overview': 'Feature overview',
  'features.businessFiles.title': 'Business files',
  'features.businessFiles.description': 'Store and share important files for your business in your Business files.',
  'features.upgradePlan.alert': 'Please upgrade your plan to use this feature.',
  'features.manage.button': 'Manage',
}

export default workspaceFeatures
