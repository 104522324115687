import { createLogic } from 'redux-logic'

import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'
import { currentWorkspaceIdSelector } from 'state/concepts/session/selectors'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataClearRelationship } from 'state/data/actions'
import { disconnectCustomDomainEndpoint } from '../endpoints'
import { DISCONNECT_CUSTOM_DOMAIN } from '../types'

const disconnectCustomDomainOperation = createLogic({
  type: DISCONNECT_CUSTOM_DOMAIN,
  latest: true,

  async process({ httpClient, getState, action }, dispatch, done) {
    const state = getState()
    const { url, endpoint } = disconnectCustomDomainEndpoint
    const workspaceId = currentWorkspaceIdSelector(state)
    const { notificationKind = 'customDomainDisconnected' } = action

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.delete(url)

      dispatch(
        dataClearRelationship({
          kind: 'workspace',
          ids: [workspaceId],
          relationship: 'customDomain',
          isPlural: false,
        }),
      )
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: `notifications.${notificationKind}`,
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default disconnectCustomDomainOperation
