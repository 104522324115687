import PropTypes from 'prop-types'
import copy from 'copy-to-clipboard'
import { useSelector } from 'react-redux'

import { getPortalUrl } from 'utils/clientPortal'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import { showNotification as showNotificationAction } from 'state/notifications/actions'
import Button from 'views/shared/Button'
import { workspaceSelector } from 'state/concepts/subdomain/selectors'

const CopyVideoConferenceButton = ({ videoConferenceCode }) => {
  const showNotification = useDispatchAction(showNotificationAction)
  const workspace = useSelector(workspaceSelector)
  const workspaceUrl = getPortalUrl(workspace)
  const handleCopy = () => {
    copy(`${workspaceUrl}/video_conference/${videoConferenceCode}`)
    showNotification({ messageObject: { id: 'notifications.quickLinkWasCopied' } })
  }

  return (
    <Button
      onClick={handleCopy}
      className="main-btn--icon-only ml-8"
      kind="secondary"
      icon="copy"
      size="medium"
      iconSize={18}
      dataCy="booking-details-copy-video-conference-button"
    />
  )
}

CopyVideoConferenceButton.propTypes = {
  videoConferenceCode: PropTypes.string.isRequired,
}

export default CopyVideoConferenceButton
