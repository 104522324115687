const workspaceSettings = {
  'workspaceSettings.title': 'Workspace settings',

  'workspaceSettings.tabs.general': 'General settings',
  'workspaceSettings.tabs.security': 'Security',

  'workspaceSettings.workspaceName': 'Workspace name',
  'workspaceSettings.workspaceNameDescription':
    'This name will be used to log to your ExpertBox workspace — choose something your _(clients) and team will recognize.',

  'workspaceSettings.transferOwnership': 'Transfer ownership',
  'workspaceSettings.transferOwnership.description':
    'Once you transfer the ownership, you won’t be able to manage subscriptions or payment settings anymore. The change will take place immediately.',
  'workspaceSettings.transferOwnership.keepAdministratorUnchecked':
    "You won't have access to any workspace settings and other _(experts)' information anymore.",
  'workspaceSettings.transferOwnership.keepAdministratorChecked': 'You won’t be able to manage your payment settings.',
  'workspaceSettings.transferOwnership.payments': 'Payments',
  'workspaceSettings.transferOwnership.companySettings': 'Company settings',
  'workspaceSettings.transferOwnership.teamMembers': 'Team members',
  'workspaceSettings.transferOwnership.appointments': '_(Appointments)',
  'workspaceSettings.transferOwnership.clients': '_(Clients)',
  'workspaceSettings.transferOwnership.services': '_(Services)',
  'workspaceSettings.transferOwnerShip.keepMeAsAdministrator': 'Keep me as administrator',
  'workspaceSettings.transferOwnerShip.transferToCompany':
    'Which account do you want to transfer {companyName} company?',
  'workspaceSettings.transferOwnerShip.employeeId': 'Name or Email',

  'workspaceSettings.deleteCompany': 'Delete workspace',
  'workspaceSettings.deleteCompany.firstParagraph': `Deleting your workspace can’t be undone. You won’t be able to restore its information, such as services, appointments, clients, chats, etc.`,
  'workspaceSettings.deleteCompanyModal.title': 'Delete workspace?',
  'workspaceSettings.deleteCompanyModal.sure?':
    'Do you want to delete the workspace with all its data? You won’t be able to restore it.',
  'workspaceSettings.deleteCompanyModal.allData': 'All workspace data',
  'workspaceSettings.deleteCompanyModal.allData.description': `All the workspace data will be deleted forever:
      your business information, clients and team members, services, appointment history, chats, files, documents, video recordings, etc.`,
  'workspaceSettings.deleteCompanyModal.appointments': `Upcoming appointments`,
  'workspaceSettings.deleteCompanyModal.appointments.description': `All your upcoming appointments will be canceled automatically, and your clients will get a full refund.`,
  'workspaceSettings.deleteCompanyModal.subscription': 'Subscription',
  'workspaceSettings.deleteCompanyModal.subscription.description': 'Your subscription will be canceled immediately.',
  'workspaceSettings.deleteCompanyModal.enterPassword': 'To delete your workspace, enter your password.',
  'workspaceSettings.deleteCompanyModal.alert.payment': 'Payment required',
  'workspaceSettings.deleteCompanyModal.alert.feeCharging':
    'You will be charged the remaining fee of <b>{fee}</b> for the current billing period.',
  'workspaceSettings.companyIsNotDeletedModal.title': `Couldn't delete your workspace`,
  'workspaceSettings.companyIsNotDeletedModal.needToPay':
    'Looks like there isn’t enough money on your balance. To delete your workspace, you need to pay the remaining fee of <b>{fee}</b> for the current billing period.',
  'workspaceSettings.companyIsNotDeletedModal.checkBilling': 'Please check your {link} and try again.',
  'workspaceSettings.companyIsNotDeletedModal.billingInfo': 'billing information',
  'workspaceSettings.security.requireTwoStepVerification': 'Require two-step verification',
  'workspaceSettings.security.requireTwoStepVerification.description':
    'Require all team members to turn on two-step verification the next time they log into the workspace.',
  'workspaceSettings.security.twoFactorVerificationMethods': 'Two-factor verification methods',
  'workspaceSettings.security.twoFactorVerificationMethods.description':
    'Set methods your team members can use to verify their identitiy when they log into the workspace.',
  'workspaceSettings.security.email': 'Email',
  'workspaceSettings.security.email.description': `Send a verification code to the email address connected to a team member's account.`,
  'workspaceSettings.security.app': 'Authenticator app',
  'workspaceSettings.security.app.description':
    'Generate a verification code using one of the authenticator apps (needs to be installed on a team member’s device).',
  'workspaceSettings.security.sms': 'Text message (SMS)',
  'workspaceSettings.security.sms.description': `Send a verification code to a team member's phone number.`,
  'workspaceSettings.security.sms.alert': `To use text messages for two-step verification, you must enable the SMS reminders & notifications feature.`,
  'workspaceSettings.security.sms.alert.button': `Enable SMS feature`,
  'workspaceSettings.security.sms.advancedPlans': `Available only for advanced plans`,
  'workspaceSettings.security.sms.advancedPlans.button': `Upgrade plan`,
  'workspaceSettings.security.rememberMe': `Enable “Remember me” for two-step verification`,
  'workspaceSettings.security.rememberMe.description': `Team members can temporarily bypass two-factor verification when logging into the workspace.`,
  'workspaceSettings.security.rememberMe.label': `Remember device for`,
  'workspaceSettings.security.rememberMe.days': `{daysNumber} days`,
}

export default workspaceSettings
