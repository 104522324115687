import { combineReducers } from '@reduxjs/toolkit'

import { SET_CUSTOM_DOMAIN, REMOVE_CUSTOM_DOMAIN } from './types'

const customDomain = (state = '', action) => {
  switch (action.type) {
    case SET_CUSTOM_DOMAIN:
      return action.domain
    case REMOVE_CUSTOM_DOMAIN:
      return ''
    default:
      return state
  }
}

export default combineReducers({
  customDomain,
})
